import { Dialog } from "@headlessui/react";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDisconnect } from "wagmi";
import {
  register,
  resendOtp,
  verifyEmail,
  verifyEpikoEmail,
  verifyOTP,
} from "../../api/adapters/auth";
import CloseIcon from "../../assets/images/close-icon.svg";
import { updateUser } from "../../redux/actions/user";
import { notify } from "../../utils/common.helper";
import { setCookie } from "../../utils/cookies.helper";
import { isEmail, isUserName } from "../../utils/helper";
import Input from "../FormControl/Input";
import ButtonLoader from "../Loader/ButtonLoader";
import OTPInput from "../OTPInput/OTPInput";
import Modal from "./Modal";

function WelcomePopup(props) {
  const {
    isPopupOpen,
    setIsPopupOpen,
    welcomeStep,
    walletAddress,
    setWelcomeStep,
    inputValue,
    setInputValue,
  } = props;
  // const [otp, setOtp] = useState();
  const [error, setError] = useState({ email: "", otp: "", username: "" });
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const [otpValue, setOTPValue] = useState(["", "", "", ""]); // State to store OTP
  const [otpError, setOTError] = useState(""); // State to store OTP validation error

  const handleOtpComplete = (otp) => {
    setOTPValue(otp.split("")); // Store OTP as an array of digits
  };

  const { mutate: mutateVerifyEmail, isPending: isEmailVerifyPending } =
    useMutation({
      mutationFn: () =>
        verifyEmail({
          sWalletAddress: walletAddress.toLowerCase(),
          sEmail: inputValue?.email,
        }),
      onSuccess: (res) => {
        if (res.data.data.isUserNameExists === false)
          setWelcomeStep("username");
        else {
          notify("success", res.data.message);
          setWelcomeStep("otp");
        }
      },
      onError: (error) => {
        if (error.status === 409 || error.status === 400) {
          setError((prev) => ({
            ...prev,
            email: "Email already exists. Please try again.",
          }));
        }
      },
    });

  const {
    mutate: mutateVerifyEpikoEmail,
    isPending: isEpikoEmailVerifyPending,
  } = useMutation({
    mutationFn: () => verifyEpikoEmail(inputValue?.email),
    onSuccess: (res) => {
      if (res.status === 200) mutateVerifyEmail();
    },
    onError: (error) => {
      if (error.status === 409)
        setError((prev) => ({
          ...prev,
          email: "Email already exists. Please try again.",
        }));
    },
  });

  const { mutate: mutateResendOtp, isPending: isResendPending } = useMutation({
    mutationFn: (otp) =>
      resendOtp({
        sEmail: inputValue?.email,
      }),
    onSuccess: (res) => {
      setCount(30);
      setError((prev) => ({ ...prev, otp: "" }));
      notify("success", res.data.message);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const { mutate: mutateVerifyOtp, isPending: isOtpPending } = useMutation({
    mutationFn: (otp) =>
      verifyOTP({
        sEmail: inputValue?.email,
        nOTP: otp,
      }),
    onSuccess: (res) => {
      notify("success", res.data.message);
      setWelcomeStep("username");
    },
    onError: (error) => {
      console.log(error);
      if (error.status === 409) {
        setError((prev) => ({
          ...prev,
          email: "Email already exists. Please try again.",
        }));
      } else if (error.status === 406) {
        setError((prev) => ({
          ...prev,
          otp: "OTP doesn't match. Please try again",
        }));
      } else if (error.status === 417) {
        setError((prev) => ({
          ...prev,
          otp: "OTP Expired. Please try again",
        }));
      }
    },
  });

  const { mutate: mutateRegister, isPending: isRegisterPending } = useMutation({
    mutationFn: (otp) =>
      register({
        sEmail: inputValue?.email,
        sUserName: inputValue?.username,
      }),
    onSuccess: (res) => {
      setCookie("_sToken", `%20${res.data.data?.sToken}%20`);
      setCookie("_userId", res.data.data?.iUserId);
      setCookie("_userName", res.data.data?.sUserName);
      dispatch(
        updateUser({
          id: res.data.data?.iUserId,
          firstName: res.data.data?.sFirstName,
          lastName: res.data.data?.sLastName,
          userName: res.data.data?.sUserName,
          bio: res.data.data?.sBio,
          email: res.data.data?.sEmail,
          profilePicUrl: res.data.data?.sProfilePicUrl,
          coverPicUrl: res.data.data?.sCoverPicUrl,
          walletAddress: res.data.data?.sWalletAddress,
          token: res.data.data?.sToken,
          isLoggedIn: true,
          aFollowers: res.data.data?.aFollowers,
          aFollowing: res.data.data?.aFollowing,
        })
      );

      notify("success", res.data.message);
      setWelcomeStep("email");
      setInputValue({
        email: "",
        username: "",
        otp: "",
      });
      setIsPopupOpen(false);
    },
    onError: (error) => {
      console.log(error);
      if (error.status === 409) {
        setError((prev) => ({
          ...prev,
          username: "Username already taken, please choose another username.",
        }));
      } else if (error.status === 500) {
        notify("error", "Something went wrong");
      }
    },
  });

  const handleSubmit = (e, step) => {
    e.preventDefault();

    switch (step) {
      case "email":
        let isValidEmail = isEmail(inputValue.email.trim());
        if (!isValidEmail) {
          setError((prev) => ({
            ...prev,
            email: "Please enter valid email address",
          }));
          return;
        }
        setError((prev) => ({ ...prev, email: "" }));
        mutateVerifyEpikoEmail();

        break;
      case "otp":
        setError((prev) => ({ ...prev, otp: "" }));
        const otp = otpValue.join(""); // Convert OTP array to a string

        if (otp.length === 4 && !isNaN(otp)) {
          setError((prev) => ({ ...prev, otp: "" }));
          mutateVerifyOtp(Number(otp));
        } else
          setError((prev) => ({
            ...prev,
            otp: "Please enter a valid 4-digit OTP",
          }));
        break;
      case "username":
        if (!isUserName(inputValue.username)) {
          return setError((prev) => ({
            ...prev,
            username:
              "Username must be 3-15 characters long. Starts with a letter and can contain letters, numbers, underscores and dots.",
          }));
        }
        setError((prev) => ({ ...prev, username: "" }));
        mutateRegister({
          sEmail: inputValue?.email,
          sUsername: inputValue?.username,
        });

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let intervalId;

    if (count > 0) {
      intervalId = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId); // Cleanup function to clear the interval
  }, [count]);

  return (
    <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
      <div className="flex justify-between items-start">
        <Dialog.Title
          as="h3"
          className="text-3xl font-black font-monserrat text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2"
        >
          Welcome!
        </Dialog.Title>
        <button
          className="focus-visible:outline-none"
          onClick={() => {
            setIsPopupOpen(false);
            setWelcomeStep("email");
            setError((prev) => ({
              email: "",
              otp: "",
              username: "",
            }));
            setInputValue({
              email: "",
              username: "",
              otp: "",
            });
            disconnect();
          }}
        >
          <img src={CloseIcon} alt="CloseIcon" className="w-7" />
        </button>
      </div>
      {welcomeStep === "email" && (
        <>
          <div className="mt-2">
            <p className="text-base font-bold text-dark-70">
              Please provide your email address.
            </p>
          </div>
          <div className="mt-7">
            <Input
              placeholder="Enter registered email address"
              label="Email"
              id="email"
              value={inputValue.email.trim()}
              changeHandler={(e) => {
                setInputValue((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
              error={error.email}
              textLimit={100}
            />
          </div>
          <div className="mt-5">
            <button
              className="btn-secondary w-full"
              onClick={(e) => {
                if (inputValue.email.trim()) handleSubmit(e, "email");
                else
                  setError((prev) => ({
                    ...prev,
                    email: "Please enter email address",
                  }));
              }}
              disabled={isEmailVerifyPending}
            >
              {isEmailVerifyPending && <ButtonLoader />}
              VERIFY
            </button>
          </div>
        </>
      )}
      {welcomeStep === "otp" && (
        <div>
          <div className="mt-2">
            <p className="text-base font-bold text-dark-70">
              Verify your email address. ({inputValue.email})
            </p>
          </div>
          <div className="mt-7">
            <label className="block text-sm font-semibold text-dark-60">
              Enter OTP
            </label>
            <div className="mt-4">
              <OTPInput
                onComplete={handleOtpComplete}
                setOTPValue={setOTPValue}
              />
              {error?.otp && (
                <p className="text-sm text-red-500 mt-2">{error.otp}</p>
              )}
            </div>
          </div>
          <div
            className="mt-3 cursor-pointer hover:text-primary duration-300 inline-block"
            onClick={(e) => {
              setError((prev) => ({ ...prev, otp: "" }));
              !count && mutateResendOtp();
            }}
          >
            Resend OTP {count > 0 && `(${count})`}
            {isResendPending && <ButtonLoader />}
          </div>
          <div className="mt-7">
            <button
              className="btn-secondary w-full"
              onClick={(e) => handleSubmit(e, "otp")}
              disabled={
                isEmailVerifyPending ||
                isEpikoEmailVerifyPending ||
                isOtpPending
              }
            >
              {(isEmailVerifyPending ||
                isEpikoEmailVerifyPending ||
                isOtpPending) && <ButtonLoader />}
              CONFIRM
            </button>
          </div>
        </div>
      )}
      {welcomeStep === "username" && (
        <div>
          <div className="mt-2">
            <p className="text-base font-bold text-dark-70">
              Set your username.
            </p>
          </div>
          <div className="mt-7">
            <Input
              placeholder="Enter username"
              label="Username"
              id="username"
              value={inputValue.username}
              changeHandler={(e) => {
                setInputValue((prev) => ({
                  ...prev,
                  username: e.target.value,
                }));
              }}
              error={error.username}
            />
          </div>
          <div className="mt-7">
            <button
              className="btn-secondary w-full"
              onClick={(e) => handleSubmit(e, "username")}
              disabled={isRegisterPending}
            >
              {isEmailVerifyPending && <ButtonLoader />}
              CONFIRM
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default WelcomePopup;
