import { Popover, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { chainSymbolById, parseDate } from '../../utils/helper';

function Notification({ notification }) {
    return (
        <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
        >
            <Popover.Panel className='absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-4 sm:px-0 sm:max-w-xs'>
                <div className='dropdown p-6 max-h-[calc(100vh-100px)] left-0 w-full overflow-auto'>
                    <h3 className='text-xl text-black font-bold md:text-lg'>
                        Notification
                    </h3>
                    {notification?.length ? (
                        <div className=''>
                            <ul>
                                {notification?.map((item) => {
                                    const transaction = item.transaction[0];
                                    const nft = transaction?.nft?.[0];
                                    const fromUser = transaction?.aFrom[0];
                                    const tokenSymbol =
                                        transaction?.sTokenSymbol ||
                                        chainSymbolById(nft?.nChainId);
                                    const userName = fromUser?.sUserName;
                                    const bidPrice = transaction?.nBidPrice;
                                    let statusMessage = '';

                                    switch (transaction?.eBidStatus) {
                                        case 'Sold':
                                            statusMessage = `Sold To ${userName} for ${bidPrice} ${tokenSymbol}`;
                                            break;
                                        case 'Followed':
                                            statusMessage =
                                                'Started following you';
                                            break;
                                        case 'Bid':
                                            statusMessage = `${userName} placed a bid of ${bidPrice} ${tokenSymbol}`;
                                            break;
                                        case 'Claimed':
                                            statusMessage = `${userName} claimed the NFT`;
                                            break;
                                        default:
                                            break;
                                    }
                                    return (
                                        <li
                                            className='flex items-center mt-6'
                                            key={item._id}
                                        >
                                            <div className='w-3 h-3 rounded-full bg-primary flex-shrink-0 mr-2.5'></div>
                                            <div>
                                                <h6 className='text-base text-black font-medium pb-1'>
                                                    {nft?.sName || userName}
                                                </h6>
                                                <p className='text-sm text-dark-70 pb-1'>
                                                    {statusMessage}
                                                </p>
                                                <span className='text-xs text-black'>
                                                    {parseDate(item.dCreatedAt)}
                                                </span>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ) : (
                        <div className='flex items-center justify-center h-20'>
                            <p className='text-sm text-black font-medium pb-1'>
                                No Notification
                            </p>
                        </div>
                    )}
                </div>
            </Popover.Panel>
        </Transition>
    );
}

export default Notification;
