import { Dialog } from "@headlessui/react";
import { ethers } from "ethers";
import React, { useState } from "react";
import { useSelector } from "react-redux";

// COMPONENTS
import Input from "../../FormControl/Input";
import Modal from "../Modal";

// IMAGES
import { useMutation } from "@tanstack/react-query";
import { editCollab } from "../../../api/adapters/collaborator";
import CloseIcon from "../../../assets/images/close-icon.svg";
import { notify } from "../../../utils/common.helper";

function EditCollaborators(props) {
  const { isPopupOpen, setIsPopupOpen, refetch, editDetail, setEditDetail } =
    props;

  const [error, setError] = useState({ name: "", walletAddress: "" });
  const user = useSelector((state) => state.user);

  const { mutate } = useMutation({
    mutationFn: () =>
      editCollab({
        sName: editDetail.name,
        sWalletAddress: editDetail.walletAddress,
        id: user.id,
        sCollaboratorId: editDetail.collaboratorId,
      }),
    onSuccess: (data) => {
      notify("success", data.data.message);
      setEditDetail({ name: "", walletAddress: "", collaboratorId: "" });
      setIsPopupOpen(false);
      refetch();
    },
    onError: (error) => {
      notify("error", error.data.message);
      setEditDetail({ name: "", walletAddress: "", collaboratorId: "" });
      setIsPopupOpen(false);
    },
  });

  const handleSubmit = () => {
    const formError = {
      name: editDetail.name ? "" : "Please enter collaborator name",
      walletAddress: !editDetail.walletAddress
        ? "Please enter collaborator wallet address"
        : !ethers.utils.isAddress(editDetail.walletAddress)
        ? "Please enter valid wallet address"
        : "",
    };

    setError((prev) => ({ ...prev, ...formError }));

    const isFormError = !Object.values(formError).some((value) => value === "");

    if (!isFormError) {
      if (ethers.utils.isAddress(editDetail.walletAddress)) return mutate();
      else return notify("error", "Invalid wallet Address");
    }
  };

  return (
    <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
      <div className="flex justify-between items-start">
        <Dialog.Title
          as="h3"
          className="text-3xl font-black font-monserrat text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2"
        >
          Edit Collaborator
        </Dialog.Title>
        <button
          className="focus-visible:outline-none"
          onClick={() => {
            setEditDetail({
              name: "",
              walletAddress: "",
              collaboratorId: "",
            });
            setIsPopupOpen(false);
          }}
        >
          <img src={CloseIcon} alt="CloseIcon" className="w-7" />
        </button>
      </div>
      <div>
        <div className="mt-10">
          <Input
            placeholder="Enter Collaborator Name"
            label="Collaborator Name"
            id="name"
            changeHandler={(e) =>
              setEditDetail((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            handleSubmit={handleSubmit}
            value={editDetail.name}
          />
        </div>
        <div className="mt-6">
          <Input
            placeholder="Enter Collaborator Address"
            label="Collaborator Address"
            id="walletAddress"
            changeHandler={(e) =>
              setEditDetail((prev) => ({
                ...prev,
                walletAddress: e.target.value,
              }))
            }
            handleSubmit={handleSubmit}
            value={editDetail.walletAddress}
            isWalletAddress={true}
          />
        </div>
        <div className="mt-6">
          <button className="btn-secondary w-full" onClick={handleSubmit}>
            EDIT
          </button>
          <button
            className="btn-border w-full mt-4"
            onClick={() => {
              setIsPopupOpen(false);
              setEditDetail({
                name: "",
                walletAddress: "",
                collaboratorId: "",
              });
            }}
          >
            CANCEL
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default EditCollaborators;
