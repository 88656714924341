import { Dialog, Menu, Popover, Transition } from '@headlessui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ConnectKitButton } from 'connectkit';
import React, {
    Fragment,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useAccount, useDisconnect } from 'wagmi';
import { getPrice } from '../../api/adapters/asset-common';
import { verifyAddress } from '../../api/adapters/auth';
import { getCart } from '../../api/adapters/cart';
import {
    getNotification,
    markNotificationAsRead,
} from '../../api/adapters/notification';
import { updateCart } from '../../redux/actions/cart';
import { updatePrice } from '../../redux/actions/price';
import { updateUser } from '../../redux/actions/user';
import { initialCartState } from '../../redux/reducers/cart';
import { initialUserState } from '../../redux/reducers/user';
import { notify } from '../../utils/common.helper';
import { getToken, removeCookie, setCookie } from '../../utils/cookies.helper';

// COMPONENTS
import WelcomePopup from '../Modal/WelcomePopup';
import AddCollection from '../Modal/collection/AddCollection';
import Navigation from './Navigation';
import Notification from './Notification';

// IMAGES
import CartIcon from '../../assets/images/cart-icon.svg';
import CloseIcon from '../../assets/images/close-icon.svg';
import Logo from '../../assets/images/logo.svg';
import LogoSmall from '../../assets/images/logo-small.svg';
import NotificationIcon from '../../assets/images/notification-icon.svg';
import SearchIcon from '../../assets/images/search-icon.svg';
import VerifyIcon from '../../assets/images/verify-icon.svg';
import WalletIcon from '../../assets/images/wallet-icon-small.svg';

function Header() {
    const [stickyHeader, setStickyHeader] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isBrandDropdown, setIsBrandDropdown] = useState(false);
    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
    const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
    const [cartItem, setCartItem] = useState(0);
    const [isWalletOpen, setIsWalletOpen] = useState(false);
    const [welcomeStep, setWelcomeStep] = useState('email');
    const [walletAddress, setWalletAddress] = useState(null);
    const [inputValue, setInputValue] = useState({
        email: '',
        otp: '',
        username: '',
    });
    const [search, setSearch] = useState('');

    const [tempNotification, setTempNotification] = useState(null);

    const headerRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isConnected, address } = useAccount();
    const { disconnect } = useDisconnect();
    const cart = useSelector((state) => state.cart);
    const user = useSelector((state) => state.user);

    useLayoutEffect(() => {
        const mainHeader = headerRef.current;
        let fixedTop = headerRef.current.offsetTop + 50;
        const fixedHeader = () => {
            if (window.pageYOffset > fixedTop) {
                mainHeader.classList.add('fixedTop');
                setStickyHeader(true);
            } else {
                mainHeader.classList.remove('fixedTop');
                setStickyHeader(false);
            }
        };
        window.addEventListener('scroll', fixedHeader);
    }, []);

    useEffect(() => {
        if (
            user.walletAddress &&
            user.walletAddress !== address?.toLowerCase()
        ) {
            setTempNotification(null);
            disconnect();
        }
    }, [address]);

    const { mutate: mutateVerifyAddress } = useMutation({
        mutationFn: () =>
            verifyAddress({
                sWalletAddress: address.toLowerCase(),
            }),
        onSuccess: (res) => {
            setCookie('_sToken', `%20${res.data.data?.sToken}%20`);
            setCookie('_userId', res.data.data?.iUserId);
            setCookie('_userName', res.data.data?.sUserName);
            dispatch(
                updateUser({
                    id: res.data.data?.iUserId,
                    firstName: res.data.data?.sFirstName,
                    lastName: res.data.data?.sLastName,
                    userName: res.data.data?.sUserName,
                    bio: res.data.data?.sBio,
                    email: res.data.data?.sEmail,
                    profilePicUrl: res.data.data?.sProfilePicUrl,
                    coverPicUrl: res.data.data?.sCoverPicUrl,
                    walletAddress: res.data.data?.sWalletAddress,
                    token: res.data.data?.sToken,
                    isVerified: res.data.data?.isUserVerified,
                    aFollowing: res.data.data?.aFollowing,
                    aFollowers: res.data.data?.aFollowers,
                    isLoggedIn: true,
                })
            );
        },
        onError: (error) => {
            if (error.status === 403) {
                disconnect();
                return notify(
                    'error',
                    'Account is blocked! Please contact admin.'
                );
            }

            if (error.status === 419) {
                console.log(error.data.data);
                setWelcomeStep('username');
                setInputValue((prev) => ({
                    ...prev,
                    email: error.data.data.sEmail
                }))
            }

            setIsWalletOpen(true);
        },
    });

    const { data: cartData } = useQuery({
        queryKey: ['cartData'],
        queryFn: () =>
            getCart().then((res) => {
                dispatch(updateCart(res.data.data));
                return res.data.data;
            }),
        enabled: user.isLoggedIn,
    });

    useQuery({
        queryKey: ['prices'],
        queryFn: () =>
            getPrice().then((res) => {
                dispatch(updatePrice(res.data.data));
                return res.data.data;
            }),
        refetchInterval: 300000, // 5 minutes
    });
    const { data: notification, refetch: refetchNotification } = useQuery({
        queryKey: ['notification'],
        queryFn: () =>
            getNotification().then((res) => {
                return res.data.data;
            }),
        enabled: user.isLoggedIn,
        refetchInterval: 60000, // 1 minute
    });

    const { mutate: mutateNotificationRead } = useMutation({
        mutationFn: () => markNotificationAsRead(),
        onSuccess: () => {
            refetchNotification();
        },
        onError: (error) => {
            notify('error', error);
        },
    });

    useEffect(() => {
        if (!isConnected) {
            dispatch(updateUser(initialUserState));
            dispatch(updateCart(initialCartState));

            removeCookie('_sToken');
            removeCookie('_userId');
            removeCookie('_userName');
        }
        if (isConnected) {
            setWalletAddress(address);
            mutateVerifyAddress();
        }
    }, [isConnected]);

    useEffect(() => {
        const quantityArray = Array.from(cart, (item, index) =>
            Number(item.nQuantity)
        );
        const quantitySum = quantityArray.reduce((acc, curr) => acc + curr, 0);
        setCartItem(quantitySum);
    }, [cartData, cart]);

    useEffect(() => {
        if (notification?.length) {
            setTempNotification(notification);
        }
    }, [notification]);

    return (
        <header
            ref={headerRef}
            className={`header py-10 border-b-2 bg-white border-dark-20 fixed w-full z-20 top-0 left-0 duration-300 lg:py-5 md:py-3.5 ${stickyHeader ? 'py-4 lg:!py-3.5' : ''
                }`}
        >
            <div className='container flex items-center justify-between gap-10 xl:gap-6 sm:gap-5 sm:px-4'>
                <div className='flex items-center'>
                    <div className='group flex items-center cursor-pointer logo relative mr-5 flex-shrink-0 2xl:mr-3 xl:max-w-[170px] md:mr-0'>
                        <div className='md:hidden' onClick={(e) => navigate('/')}>
                            <img src={Logo} alt='Logo' />
                        </div>
                        <div
                            onClick={(e) => navigate('/')}
                            className='hidden md:block md:w-14'
                        >
                            <img src={LogoSmall} alt='Logo' />
                        </div>
                        {/* <div
                            onClick={() => setIsBrandDropdown(!isBrandDropdown)}
                            className='hidden md:block md:w-14'
                        >
                            <img src={LogoSmall} alt='Logo' />
                        </div>
                        <div className='ml-5 duration-300 group-hover:rotate-180  2xl:ml-3 md:ml-2'>
                            <img
                                src={CollapseIcon}
                                alt='CollapseIcon'
                                className='w-8 2xl:w-7 md:w-6'
                            />
                        </div>
                        <div
                            className={`dropdown opacity-0 top-full invisible w-60 duration-300 translate-y-4 group-hover:opacity-100 group-hover:translate-y-2 group-hover:visible ${
                                isBrandDropdown
                                    ? 'opacity-100 translate-y-2 visible'
                                    : ''
                            }`}
                        >
                            <div>
                                <Link
                                    to='https://epiko.io/'
                                    target='_blank'
                                    className='font-medium text-black text-base px-10 py-4 block duration-300 hover:bg-primary/20'
                                >
                                    Epiko
                                </Link>
                            </div>
                            <div>
                                <Link
                                    to='https://www.epikoregal.com/'
                                    target='_blank'
                                    className='font-medium text-black text-base px-10 py-4 block duration-300 hover:bg-primary/20'
                                >
                                    Epiko Regal
                                </Link>
                            </div>
                            <div>
                                <Link
                                    to='https://epikoworld.com/'
                                    target='_blank'
                                    className='font-medium text-black text-base px-10 py-4 block duration-300 hover:bg-primary/20'
                                >
                                    Epiko World
                                </Link>
                            </div>
                            <div>
                                <Link
                                    to='https://epikovalley.com/'
                                    target='_blank'
                                    className='font-medium text-black text-base px-10 py-4 block duration-300 hover:bg-primary/20'
                                >
                                    Epiko Valley
                                </Link>
                            </div>
                        </div> */}
                    </div>
                    <div className='nav border-l-2 border-dark-40 py-3 pl-8  2xl:pl-6 xl:pl-5 lg:hidden'>
                        <Navigation setIsAddPopupOpen={setIsAddPopupOpen} />
                    </div>
                </div>
                <div className='flex items-center gap-7 w-1/2 max-w-3xl 2xl:gap-5 xl:gap-4 lg:w-9/12 md:w-auto sm:gap-2'>
                    <button
                        className='hidden md:block group bg-dark-10 py-4 px-4 rounded-full duration-300 relative after:absolute after:w-full after:h-full after:bg-gradient after:opacity-0 after:duration-700 after:rounded-full after:top-0 after:left-0 hover:after:opacity-100 lg:p-2.5 sm:after:hidden'
                        onClick={() =>
                            setIsMobileSearchOpen(!isMobileSearchOpen)
                        }
                    >
                        <img
                            src={SearchIcon}
                            alt='SearchIcon'
                            className='relative z-10 duration-300 group-hover:invert lg:w-5 sm:group-hover:invert-0'
                        />
                    </button>
                    <label
                        className={`relative block grow md:hidden ${isMobileSearchOpen
                            ? 'md:fixed md:left-0 md:top-[85px] md:w-full md:bg-white md:px-5 md:py-3 md:!block md:shadow-md'
                            : ''
                            }`}
                    >
                        <span
                            className={`absolute inset-y-0 right-4 flex items-center pl-2 ${isMobileSearchOpen ? 'md:right-7' : ''
                                }`}
                        >
                            <Link
                                to={`/search?q=${encodeURIComponent(search)}`}
                                target=''
                                onClick={(e) => {
                                    if (!search) {
                                        e.preventDefault();
                                    }
                                }}
                            >
                                <img src={SearchIcon} alt='SearchIcon' />
                            </Link>
                        </span>
                        <input
                            className='placeholder:text-dark-80 text-dark-60 font-bold block bg-dark-10 w-full border border-dark-30 rounded-xl py-4 pr-10 pl-3 focus:outline-none focus:border-dark-70 active:border-dark-70 focus:ring-0 focus:!shadow-transparent sm:text-sm lg:py-2.5'
                            placeholder='Search by NFT, Collection or User'
                            type='text'
                            name='search'
                            autoComplete='off'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyUp={(e) => {
                                if (search)
                                    if (e.key === 'Enter') {
                                        navigate(
                                            `/search?q=${encodeURIComponent(
                                                search
                                            )}`
                                        );
                                    }
                            }}
                        />
                    </label>
                    {isConnected && getToken() && (
                        <>
                            <Popover className='relative'>
                                {({ open }) => (
                                    <>
                                        <Popover.Button
                                            className={`group bg-dark-10 py-4 px-4 rounded-full duration-300 relative focus-visible:outline-none after:absolute after:w-full after:h-full after:bg-gradient after:opacity-0 after:duration-700 after:rounded-full after:top-0 after:left-0 hover:after:opacity-100 lg:p-2.5 sm:after:hover:opacity-0 ${open
                                                ? 'after:opacity-100 sm:after:!opacity-100'
                                                : ''
                                                }`}
                                            onClick={() => {
                                                if (notification?.length) {
                                                    mutateNotificationRead();
                                                }
                                            }}
                                        >
                                            <img
                                                src={NotificationIcon}
                                                alt='NotificationIcon'
                                                className={`relative z-10 duration-300 group-hover:invert lg:w-5 sm:group-hover:invert-0 ${open
                                                    ? 'invert sm:!invert'
                                                    : ''
                                                    }`}
                                            />
                                            {user.isLoggedIn &&
                                                notification?.length ? (
                                                <span className='bg-error text-xs text-white font-bold px-1.5 py-1 rounded-lg absolute -top-3 -right-3 z-10 sm:text-xxs sm:-right-1'>
                                                    {notification?.length}
                                                </span>
                                            ) : null}
                                        </Popover.Button>
                                        <Notification
                                            notification={tempNotification}
                                        />
                                    </>
                                )}
                            </Popover>
                            <Link
                                to='/my-cart'
                                className='group bg-dark-10 py-4 px-4 rounded-full duration-300 relative after:absolute after:w-full after:h-full after:bg-gradient after:opacity-0 after:duration-700 after:rounded-full after:top-0 after:left-0 hover:after:opacity-100 lg:p-2.5  sm:after:hidden'
                                onClick={(e) => {
                                    if (!isConnected) {
                                        e.preventDefault();
                                        notify(
                                            'error',
                                            'Please connect your wallet!'
                                        );
                                    }
                                }}
                            >
                                <img
                                    src={CartIcon}
                                    alt='CartIcon'
                                    className='relative z-10 duration-300 group-hover:invert lg:w-5 sm:group-hover:invert-0'
                                />
                                {user.isLoggedIn && cartItem ? (
                                    <span className='bg-error text-xs text-white font-bold px-1.5 py-1 rounded-lg absolute -top-3 -right-3 z-10 sm:text-xxs sm:-right-1'>
                                        {cartItem}
                                    </span>
                                ) : null}
                            </Link>
                        </>
                    )}
                    {/* <ConnectKitButton /> */}

                    {isConnected && user.isLoggedIn ? (
                        <ConnectKitButton.Custom>
                            {({ show, hide }) => {
                                return (
                                    <div className='relative'>
                                        <Menu
                                        // as='div'
                                        // className='relative inline-block text-left'
                                        >
                                            <Menu.Button
                                                className='btn-secondary whitespace-nowrap justify-start py-2.5 px-4 xl:px-2 lg:py-1.5 md:w-10 md:rounded-full md:p-0 md:text-[0px]'
                                            // onClick={show}
                                            >
                                                <div className='relative rounded-full mr-3 w-[34px] h-[34px] lg:w-6 lg:h-6 md:max-w-none md:w-9 md:h-9 md:mr-0'>
                                                    {user.profilePicUrl ? (
                                                        <img
                                                            src={
                                                                user.profilePicUrl +
                                                                `?${Date.now()}` // To prevent caching
                                                            }
                                                            alt='EpikoProfileIcon'
                                                            className='absolute rounded-full object-cover object-center w-full h-full top-0 left-0'
                                                            onError={(e) => {
                                                                e.target.onerror =
                                                                    null; // Prevents infinite loop in case of continuous errors
                                                                e.target.style.display =
                                                                    'block'; // Adjust as needed
                                                                e.target.src =
                                                                    'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Transparent GIF
                                                            }}
                                                        />
                                                    ) : (
                                                        <div className='absolute rounded-full object-cover object-center w-full h-full top-0 left-0 bg-dark-10 bg-gradient'></div>
                                                    )}
                                                    <span className='absolute -bottom-1 -right-1 md:mr'>
                                                        {user?.isVerified && (
                                                            <img
                                                                src={VerifyIcon}
                                                                alt='VerifyIcon'
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                                <span className="md:hidden">View Profile</span>
                                            </Menu.Button>

                                            <Transition
                                                as={Fragment}
                                                enter='transition ease-out duration-100'
                                                enterFrom='transform opacity-0 translate-y-4'
                                                enterTo='transform opacity-100 translate-y-2'
                                                leave='transition ease-in duration-75'
                                                leaveFrom='transform opacity-100 translate-y-2'
                                                leaveTo='transform opacity-0 translate-y-4'
                                            >
                                                <Menu.Items className='dropdown w-72 right-0'>
                                                    <div className=''>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link
                                                                    to='/my-profile'
                                                                    className='font-medium text-black text-base px-10 py-4 block duration-300 hover:bg-primary/20'
                                                                >
                                                                    My Profile
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link
                                                                    to='/manage-bid'
                                                                    className='font-medium text-black text-base px-10 py-4 block duration-300 hover:bg-primary/20'
                                                                >
                                                                    Manage Bids
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link
                                                                    to='/my-nft'
                                                                    className='font-medium text-black text-base px-10 py-4 block duration-300 hover:bg-primary/20'
                                                                >
                                                                    My NFT
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link
                                                                    to='/manage-collaborators'
                                                                    className='font-medium text-black text-base px-10 py-4 block duration-300 hover:bg-primary/20'
                                                                >
                                                                    Manage
                                                                    Collaborators
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link
                                                                    to='/'
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        show();
                                                                    }}
                                                                    className='font-medium text-black text-base px-10 py-4 block duration-300 hover:bg-primary/20'
                                                                >
                                                                    Disconnect
                                                                    Wallet
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                );
                            }}
                        </ConnectKitButton.Custom>
                    ) : (
                        <ConnectKitButton.Custom>
                            {({ show, hide }) => {
                                return (
                                    <button
                                        onClick={show}
                                        className='btn-primary justify-start md:w-10 md:rounded-full md:pl-2.5 md:py-[9px]'
                                    >
                                        <img
                                            src={WalletIcon}
                                            alt='WalletIcon'
                                            className='mr-3 w-6 md:max-w-none md:w-5'
                                        />
                                        WALLET
                                    </button>
                                );
                            }}
                        </ConnectKitButton.Custom>
                    )}

                    <div className='hidden lg:block -mr-1'>
                        <button
                            type='button'
                            className='gap-1.5 flex flex-col p-2'
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className='w-7 h-[3px] bg-black rounded block'></span>
                            <span className='w-7 h-[3px] bg-black rounded block'></span>
                            <span className='w-7 h-[3px] bg-black rounded block'></span>
                        </button>
                    </div>
                </div>
                <Transition
                    show={mobileMenuOpen}
                    as={Fragment}
                    enter='transition ease-out duration-500'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='transition ease-in duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <Dialog
                        as='div'
                        className='hidden lg:block'
                        onClose={setMobileMenuOpen}
                    >
                        <div className='fixed inset-0 z-20 bg-black/50' />
                        <Dialog.Panel className='fixed z-20 inset-y-0 right-0 w-80 overflow-y-auto bg-white px-6 py-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
                            <div className='flex items-center justify-between'>
                                <Link to='/' className='-m-1.5 p-1.5'>
                                    <img
                                        className='w-36'
                                        src={Logo}
                                        alt='Logo'
                                    />
                                </Link>
                                <button
                                    type='button'
                                    className='-m-2.5 rounded-md p-2.5 text-gray-700'
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <img
                                        src={CloseIcon}
                                        alt='CloseIcon'
                                        className='w-8'
                                    />
                                </button>
                            </div>
                            <div className='mt-8 flow-root'>
                                <div className='-my-6 divide-y divide-gray-500/10'>
                                    <div className='space-y-2 py-6 group mobile-active'>
                                        {/* <Disclosure as='div' className='-mx-3'>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className='flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                                                    Product
                                                </Disclosure.Button>
                                                <Disclosure.Panel className='mt-2 space-y-2'>
                                                    {[
                                                        ...products,
                                                        ...callsToAction,
                                                    ].map((item) => (
                                                        <Disclosure.Button
                                                            key={item.name}
                                                            as='a'
                                                            href={item.href}
                                                            className='block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                                                        >
                                                            {item.name}
                                                        </Disclosure.Button>
                                                    ))}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure> */}
                                        <Navigation
                                            setMobileMenuOpen={
                                                setMobileMenuOpen
                                            }
                                            setIsAddPopupOpen={
                                                setIsAddPopupOpen
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </Transition>
            </div>
            <WelcomePopup
                isPopupOpen={isWalletOpen}
                setIsPopupOpen={setIsWalletOpen}
                welcomeStep={welcomeStep}
                walletAddress={walletAddress}
                setWelcomeStep={setWelcomeStep}
                inputValue={inputValue}
                setInputValue={setInputValue}
            />
            <AddCollection
                isPopupOpen={isAddPopupOpen}
                setIsPopupOpen={setIsAddPopupOpen}
            // refetch={refetch}
            />
        </header>
    );
}

export default Header;
