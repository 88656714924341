import axios from "axios";
import { apiClient } from "../client/api-client";

export const login = async (data) => {
  let url = `/auth/login`;
  return await apiClient({ url, method: "POST", data });
};

export const verifyAddress = async (data) => {
  let url = `/auth/verify-walletAddress`;
  return await apiClient({ url, method: "POST", data });
};

export const verifyEmail = async (data) => {
  let url = `/auth/verify-email`;
  return await apiClient({ url, method: "POST", data });
};

export const verifyOTP = async (data) => {
  let url = `/auth/verify-otp`;
  return await apiClient({ url, method: "POST", data });
};

export const register = async (data) => {
  let url = `/auth/set-username-and-login`;
  return await apiClient({ url, method: "POST", data });
};
export const resendOtp = async (data) => {
  let url = `/auth/resend-otp`;
  return await apiClient({ url, method: "POST", data });
};

export const verifyUser = async (data) => {
  let url = `/auth/verifyUser`;
  return await apiClient({ url, method: "POST", data });
};

// Central DB APIs

export const verifyEpikoEmail = async (data) => {
  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "https://api-hub.epiko.io"
      : "https://beta-api-hub.epiko.io";

  let url = `${baseUrl}/api/v1/market/verifyEmail/${data}`;
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.error(error);
    throw error.response;
  }
  // return await apiClient({ url, method: 'POST', data });
};
