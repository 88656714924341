"use client";

import axios from "axios";
import { useEffect, useState } from "react";
import { MDY } from "../../utils/helper";
import { Link } from "react-router-dom";

// images
import Arrow2 from "../../assets/images/arrow-2.svg";
import ArrowRight from "../../assets/images/up-right-arrow.svg";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  const [articles, setArticle] = useState(3);
  const [more, setMore] = useState(true);
  const org = "Epiko Market";

  const fetchBlogs = async () => {
    try {
      const data = await axios.get(
        `https://whitelistapi.epiko.io/api/v1/blog/article?orgName=${org}`
      );
      const response = data?.data?.data?.payload;
      // console.log({ response });
      setBlogs(response);
    } catch (error) {
      console.log("blog error", error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const loadMore = () => {
    if (blogs.length) setArticle(blogs?.length);
    if (blogs.length === articles) setMore(false);
    setMore(false);
  };

  return (
    <div className=" w-full py-32  font-inter" id="ourblogs">
      <div className="flex justify-center py-6">
        <div className="w-full container max-w-[1528px] mx-auto relative ">
          <div className="flex flex-col items-center gap-10 py-10 relative">
            <div className="flex items-center justify-between w-full">
              {/* ttitle */}
              <div className=" relative pb-3">
                <div className="main-heading-h2">
                  <p>Blogs</p>
                </div>
              </div>
              <div>
                {more && articles > 3 ? (
                  <button
                    type="button"
                    onClick={loadMore}
                    className="group btn-border xs:sm:btn-sm"
                  >
                    View All
                    <img
                      src={Arrow2}
                      alt="Arrow2"
                      className="ml-1 duration-300 invert group-hover:invert-0"
                    />
                  </button>
                ) : null}
              </div>
            </div>
          </div>

          {/* blod cards */}
          <div className="grid gap-y-3  xs:sm:grid-cols-1  md:grid-cols-2 grid-cols-3   gap-x-3 xs:sm:py-7">
            {blogs
              ?.filter((items) => items.bStatus === true)
              ?.slice(0, articles)
              .map((items, index) => (
                <Link key={index} to={`blog/${items?._id}`} className="w-full">
                  <div className="w-full flex items-center justify-center rounded-lg bg-white border-[1px] border-black/10 text-black transition-all duration-300 delay-150 hover:-translate-y-2 hover:scale-100 cursor-pointer">
                    <div className="w-full  rounded-lg flex items-center justify-center">
                      <div className="rounded-lg w-full">
                        <div className=" h-[20rem] lg:xl:h-[22rem]  2xl:h-[26rem] 3xl:h-[30rem] 4xl:h-[34rem] max-h-[34rem] overflow-hidden relative">
                          <img
                            src={items?.sImage}
                            alt={items?.sTitle}
                            className="w-full object-cover  h-full rounded-t-lg pb-3 bg-secondaryBlue hover:bg-secondaryBlue flex justify-center"
                          />

                          <div className="absolute bottom-0 left-0 right-0 px-5 pt-3 pb-7 h-[8rem] xs:sm:h-[8rem]  backdrop-blur-md bg-bg_gradient flex flex-col items-start gap-3 rounded-b-lg">
                            <div className="flex items-center justify-between w-full">
                              <p className="text-white text-lg md:text-base xs:sm:text-base line-clamp-2 flex-[70%] font-semibold">
                                {items.sTitle}
                              </p>
                              <div className="flex justify-end flex-[30%]">
                                <img
                                  src={ArrowRight}
                                  alt=""
                                  className=" w-6 h-6 "
                                />
                              </div>
                            </div>
                            <div className="flex items-center justify-between w-full font-monserrat">
                              <p className="border border-dark-40 text-dark-30   px-3 py-1 text-base md:text-sm xs:sm:text-sm   rounded-lg">
                                {items.sCategory}
                              </p>
                              <p className=" text-dark-30 text-base md:text-sm xs:sm:text-sm font-monserrat ">
                                {MDY(items?.createdAt)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
