import { Dialog } from "@headlessui/react";
import { ethers } from "ethers";
import React, { useState } from "react";
import { useSelector } from "react-redux";

// COMPONENTS
import Input from "../../FormControl/Input";
import Modal from "../Modal";

// IMAGES
import { useMutation } from "@tanstack/react-query";
import { addCollab } from "../../../api/adapters/collaborator";
import CloseIcon from "../../../assets/images/close-icon.svg";
import { notify } from "../../../utils/common.helper";

function AddCollaborators(props) {
  const { isPopupOpen, setIsPopupOpen, refetch } = props;
  const [inputValue, setInputValue] = useState({
    name: "",
    walletAddress: "",
  });
  const [error, setError] = useState({ name: "", walletAddress: "" });
  const user = useSelector((state) => state.user);

  const { mutate } = useMutation({
    mutationFn: (data) =>
      addCollab({
        sName: inputValue.name.trim(),
        sWalletAddress: inputValue.walletAddress.trim(),
        id: user.id,
      }),
    onSuccess: (data) => {
      notify("success", data.data.message);
      setInputValue({ name: "", walletAddress: "" });
      setError({ name: "", walletAddress: "" });
      setIsPopupOpen(false);
      refetch();
    },
    onError: (error) => {
      notify("error", error.data.message);
      setInputValue({ name: "", walletAddress: "" });
      setError({ name: "", walletAddress: "" });
      setIsPopupOpen(false);
    },
  });

  const handleSubmit = () => {
    const formError = {
      name: inputValue.name ? "" : "Please enter collaborator name",
      walletAddress: !inputValue.walletAddress
        ? "Please enter collaborator wallet address"
        : !ethers.utils.isAddress(inputValue.walletAddress)
          ? "Please enter valid wallet address"
          : inputValue.walletAddress.toLowerCase() === user.walletAddress
            ? "You cannot add yourself as a collaborator"
            : "",
    };

    setError((prev) => ({ ...prev, ...formError }));

    const isFormError = Object.values(formError).every((value) => value === "");

    if (isFormError) mutate();
  };

  return (
    <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
      <div className="flex justify-between items-start">
        <Dialog.Title
          as="h3"
          className="text-3xl font-black font-monserrat text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2"
        >
          Add Collaborators
        </Dialog.Title>
        <button
          className="focus-visible:outline-none"
          onClick={() => {
            setInputValue({ name: "", walletAddress: "" });
            setError({ name: "", walletAddress: "" });
            setIsPopupOpen(false);
          }}
        >
          <img src={CloseIcon} alt="CloseIcon" className="w-7" />
        </button>
      </div>
      <div>
        <div className="mt-10">
          <Input
            placeholder="Enter Collaborator Name"
            label="Collaborator Name"
            id="name"
            changeHandler={(e) =>
              setInputValue((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            value={inputValue.name}
            handleSubmit={handleSubmit}
            type="text"
            error={error.name}
          />
        </div>
        <div className="mt-6">
          <Input
            placeholder="Enter Collaborator Address"
            label="Collaborator Address"
            id="walletAddress"
            changeHandler={(e) =>
              setInputValue((prev) => ({
                ...prev,
                walletAddress: e.target.value,
              }))
            }
            isWalletAddress={true}
            value={inputValue.walletAddress}
            handleSubmit={handleSubmit}
            type="text"
            error={error.walletAddress}
          />
        </div>
        <div className="mt-6">
          <button className="btn-secondary w-full" onClick={handleSubmit}>
            CREATE
          </button>
          <button
            className="btn-border w-full mt-4"
            onClick={() => {
              setInputValue({ name: "", walletAddress: "" });
              setError({ name: "", walletAddress: "" });
              setIsPopupOpen(false);
            }}
          >
            CANCEL
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AddCollaborators;
