import { Dialog } from "@headlessui/react";
import { useMutation } from "@tanstack/react-query";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { follow, unFollow } from "../../api/adapters/user";
import { notify } from "../../utils/common.helper";
import { formatNumberWithK } from "../../utils/helper";
import { useAccount } from 'wagmi';

// COMPONENTS
import Modal from "./Modal";

// IMAGES
import CloseIcon from "../../assets/images/close-icon.svg";
import { updateUser } from "../../redux/actions/user";
import { getToken } from "../../utils/cookies.helper";

const UserItem = memo(({ item, isFollowing, user, mutateFollow, mutateUnFollow }) => {
  const dispatch = useDispatch();
  const { isConnected } = useAccount();

  return (
    <div className="flex items-center gap-4 xs:gap-3" key={item?._id}>
      <div>
        <div className="relative w-14 pt-[100%] xs:w-10">
          {item?.sProfilePicUrl ? (
            <img
              src={item?.sProfilePicUrl}
              alt="ProfileIcon"
              className="absolute left-0 top-0 object-cover rounded-full w-full h-full object-center w-full h-full"
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop in case of continuous errors
                e.target.style.display = "block"; // Adjust as needed
                e.target.src =
                  "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="; // Transparent GIF
              }}
            />
          ) : (
            <div className="absolute left-0 top-0 rounded-full w-full h-full object-cover object-center w-full h-full bg-gradient"></div>
          )}
        </div>
      </div>
      <div className="flex-1">
        <h6 className="text-base text-black font-bold mb-1 xs:mb-0">
          {item?.sUserName}
        </h6>
        <p className="text-xs text-dark-60">
          {formatNumberWithK(item?.nFollowersLength)} Followers
        </p>
      </div>
      {user.id !== item._id && (
        <div>
          {!isFollowing && user?.aFollowing?.includes(item._id) ? (
            <button
              className="btn-secondary bg-dark-10 text-black border-transparent text-xs py-4 min-w-[138px] xs:py-3 xs:min-w-[105px] hover:border-black"
              onClick={(e) => {
                e.preventDefault();
                mutateUnFollow({
                  sFollowingId: item._id,
                });
                const newArr = user?.aFollowing.filter(
                  (following) => following !== item._id
                );

                dispatch(
                  updateUser({
                    ...user,
                    aFollowing: newArr,
                  })
                );
              }}
            >
              Unfollow
            </button>
          ) : isFollowing && user?.aFollowing?.includes(item._id) ? (
            <button className="btn-secondary text-xs py-4 min-w-[138px] xs:py-3 xs:min-w-[105px]">
              Following
            </button>
          ) : (
            <button
              className="btn-secondary text-xs py-4 min-w-[138px] xs:py-3 xs:min-w-[105px]"
              onClick={(e) => {
                e.preventDefault();
                if (!getToken() || !isConnected)
                  return notify(
                    'error',
                    'Please connect your wallet!'
                  );
                mutateFollow({
                  sFollowingId: item._id,
                });
                const newArr = [...user?.aFollowing, item._id];
                dispatch(
                  updateUser({
                    ...user,
                    aFollowing: newArr,
                  })
                );
              }}
            >
              Follow
            </button>
          )}
        </div>
      )}
    </div>
  );
});

function FollowingFollowersPopup(props) {
  const {
    isPopupOpen,
    setIsPopupOpen,
    isFollowing,
    data,
    refetch,
  } = props;

  const user = useSelector((state) => state.user);

  const { mutate: mutateFollow } = useMutation({
    mutationFn: (data) => follow(data),
    onSuccess: (data) => {
      refetch();
      // refetchFollowerFollowing();
    },
    onError: (error) => {
      notify("error", error);
    },
  });

  const { mutate: mutateUnFollow } = useMutation({
    mutationFn: (data) => unFollow(data),
    onSuccess: (data) => {
      refetch();
      // refetchFollowerFollowing();
    },
    onError: (error) => {
      console.log(error);
      notify("error", error);
    },
  });

  return (
    <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
      <div className="flex justify-between items-start">
        <Dialog.Title
          as="h3"
          className="text-3xl font-black font-monserrat text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2"
        >
          {isFollowing ? "Followers" : "Following"}
        </Dialog.Title>
        <button
          className="focus-visible:outline-none"
          onClick={() => setIsPopupOpen(false)}
        >
          <img src={CloseIcon} alt="CloseIcon" className="w-7" />
        </button>
      </div>
      <div className="mt-5 border-t border-dark-30 pt-5 flex flex-col gap-4">
        {data?.length ? (
          data?.map((item) => (
            <UserItem
              key={item?._id}
              item={item}
              isFollowing={isFollowing}
              user={user}
              mutateFollow={mutateFollow}
              mutateUnFollow={mutateUnFollow}
            />
          ))
        ) : (
          <div className="flex justify-center items-center h-96">
            <h4 className="text-2xl font-bold text-black">
              No {isFollowing ? "Followers" : "Following"}
            </h4>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default FollowingFollowersPopup;