import Cookies from "universal-cookie";

const cookies = new Cookies();

export const setCookie = (key, value) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 1);

  cookies.set(key, value, { expires, path: "/" });
};

export const getCookie = (key) => cookies.get(key);

export const removeCookie = (key) => {
  cookies.remove(key, { path: "/" });
};

export const getToken = () => {
  return getCookie("_sToken")?.replaceAll("%20", "");
};
