import React from "react";

// IMAGES
import CollectionIcon from "../../assets/images/collection-icon.svg";
import NftsIcon from "../../assets/images/nfts-icon.svg";
import SaleIcon from "../../assets/images/sale-icon.svg";
import WalletIcon from "../../assets/images/wallet-icon.svg";

function CreateSellNfts() {
  return (
    <section className="bg-darkGray">
      <div className="container text-center section-space">
        <h2 className="main-heading-h2">Create and Sell Your NFTs</h2>
        <div className="flex gap-9 max-w-[1410px] mx-auto pt-24 2xl:pt-20 xl:pt-16 lg:gap-6 md:pt-14 sm:pt-10 md:flex-col">
          <div className="text-center relative flex-1 before:w-3/5 before:h-[3px] before:bg-gradient before:absolute before:top-11 before:left-3/4 lg:before:w-1/2 lg:before:left-[80%] md:text-left md:flex md:items-start md:before:left-10 md:before:top-20 md:before:w-[3px] md:before:h-[calc(100%-50px)] sm:before:left-8 sm:before:top-14 sm:before:h-[calc(100%-30px)]">
            <div className="bg-black rounded-full p-6 inline-block flex-shrink-0 relative md:p-4 md:mr-4">
              <img src={WalletIcon} alt="WalletIcon" className="w-12 sm:w-9" />
            </div>
            <div>
              <h5 className="text-2xl lg:text-xl text-white font-medium font-monserrat">
                Setup Wallet
              </h5>
              <p className="text-sm text-dark-50 mt-4">
                You have to set up your wallet of your choice and connect, by
                clicking the wallet tab after signing up.
              </p>
            </div>
          </div>
          <div className="text-center relative flex-1 before:w-3/5 before:h-[3px] before:bg-gradient before:absolute before:top-11 before:left-3/4 lg:before:w-1/2 lg:before:left-[80%] md:text-left md:flex md:items-start md:before:left-10 md:before:top-20 md:before:w-[3px] md:before:h-[calc(100%-50px)] sm:before:left-8 sm:before:top-14 sm:before:h-[calc(100%-30px)]">
            <div className="bg-black rounded-full p-6 inline-block flex-shrink-0 relative md:p-4 md:mr-4">
              <img
                src={CollectionIcon}
                alt="CollectionIcon"
                className="w-12 sm:w-9"
              />
            </div>
            <div>
              <h5 className="text-2xl lg:text-xl text-white font-medium font-monserrat">
                Create Collection
              </h5>
              <p className="text-sm text-dark-50 mt-4">
                Click collection tab and set up your collection. Add social
                links, a description, profile and banner.
              </p>
            </div>
          </div>
          <div className="text-center relative flex-1 before:w-3/5 before:h-[3px] before:bg-gradient before:absolute before:top-11 before:left-3/4 lg:before:w-1/2 lg:before:left-[80%] md:text-left md:flex md:items-start md:before:left-10 md:before:top-20 md:before:w-[3px] md:before:h-[calc(100%-50px)] sm:before:left-8 sm:before:top-14 sm:before:h-[calc(100%-30px)]">
            <div className="bg-black rounded-full p-6 inline-block flex-shrink-0 relative md:p-4 md:mr-4">
              <img src={NftsIcon} alt="NftsIcon" className="w-12 sm:w-9" />
            </div>
            <div>
              <h5 className="text-2xl lg:text-xl text-white font-medium font-monserrat">
                Add NFTs
              </h5>
              <p className="text-sm text-dark-50 mt-4">
                Upload your work (image, video, audio or 3D art), add a title
                and description and customize NFTs.
              </p>
            </div>
          </div>
          <div className="text-center relative flex-1 md:text-left md:flex md:items-start">
            <div className="bg-black rounded-full p-6 inline-block flex-shrink-0 md:p-4 md:mr-4">
              <img src={SaleIcon} alt="SaleIcon" className="w-12 sm:w-9" />
            </div>
            <div>
              <h5 className="text-2xl lg:text-xl text-white font-medium font-monserrat">
                List Them for Sale
              </h5>
              <p className="text-sm text-dark-50 mt-4">
                Choose between auctions, fixed-price listings and
                declining-price listings. We’ll help you sell them.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateSellNfts;
