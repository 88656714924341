import { UPDATE_USER } from "../../constants/redux-events";

export const initialUserState = {
  id: "",
  firstName: "",
  lastName: "",
  userName: "",
  bio: "",
  email: "",
  profilePicUrl: "",
  coverPicUrl: "",
  walletAddress: "",
  isVerified: false,
  isLoggedIn: false,
  aFollowing: [],
  aFollowers: [],
};

const user = (state = initialUserState, { type, payload }) => {
  return type === UPDATE_USER ? { ...payload } : state;
};

export default user;
