import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CONFIG } from '../../configs/platform.config';
import { toFixedNumber } from '../../utils/helper';

// IMAGES

function CardLarge({ data, redirect }) {
    const modelRef = useRef();

    const [imageUrls, setImageUrls] = useState([]);

    // Populate imageUrls state on component mount or when data changes
    useEffect(() => {
        if (data?.aNfts) {
            const urls = data.aNfts.map(item =>
                item?.bIsMigrated ? `${CONFIG.sPinataIpfsUri}${item?.sImageHash}` : `${CONFIG.sAzureBlobLocation}${item?.sJsonHash}`
            );
            setImageUrls(urls);
        }
    }, [data]);

    // Step 2: Update handleError to accept index and update specific imageUrl
    const handleError = (index) => {
        const newImageUrls = [...imageUrls];
        newImageUrls[index] = `${CONFIG.sPinataIpfsUri}${data?.aNfts[index]?.sImageHash}`; // Your fallback image URL
        setImageUrls(newImageUrls);
    };

    return (
        <div className='group relative bg-black rounded-xl border border-black p-1 duration-300 hover:bg-white hover:border-dark-20 hover:-translate-y-2.5'>
            <Link
                to={redirect === '' ? '' : `${redirect}`}
                className='absolute left-0 top-0 w-full h-full z-10'
            ></Link>
            <div className='relative grid grid-cols-3 grid-rows-3'>
                {data?.aNfts?.map((item, i) => {
                    return i < 3 ? (
                        <div
                            key={i}
                            className='relative rounded-xl pt-[70%] first:row-span-3 first:col-span-2 first:last:col-span-3 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:border-[5px] before:z-[2] before:rounded-xl group-hover:before:border-white'
                        >
                            {item.eType === 'image' ? (
                                <img
                                    src={imageUrls[i]}
                                    alt={item.image}
                                    className='rounded-xl absolute top-0 left-0 w-full h-full object-cover object-center scale-[0.99]'
                                    onError={() => handleError(i)}
                                />
                            ) : item.eType === 'model' ? (
                                <model-viewer
                                    alt={data.title}
                                    src={imageUrls[i]}
                                    poster={imageUrls[i]}
                                    shadow-intensity='1'
                                    camera-controls={false}
                                    ref={(ref) => {
                                        modelRef.current = ref;
                                    }}
                                    class='rounded-xl absolute top-0 left-0 w-full h-full object-cover object-center scale-[0.99]'
                                    onError={() => handleError(i)}
                                ></model-viewer>
                            ) : (
                                <video
                                    className='rounded-xl absolute top-0 left-0 w-full h-full object-cover object-center scale-[0.99]'
                                    src={imageUrls[i]}
                                    onError={() => handleError(i)}
                                />
                            )}
                        </div>
                    ) : null;
                })}
                {data?.aNfts?.length > 3 ? (
                    <div className='relative rounded-xl pt-[70%] first:row-span-3 first:col-span-2 first:last:col-span-3 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:border-[5px] before:z-[2] before:rounded-xl group-hover:before:border-white'>
                        <div className='bg-primary absolute top-0 left-0 w-full h-full rounded-2xl'>
                            <span className='text-xl lg:text-base font-bold absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4'>
                                {data?.aNfts?.length - 3}+
                            </span>
                        </div>
                    </div>
                ) : null}
            </div>
            <div className='bg-black/25 backdrop-blur-xl rounded-2xl p-1 ml-5 -mt-10 z-[2] relative w-20 h-20 lg:w-16 lg:h-16'>
                {data?.user?.sImageUrl || data?.sCollectionPicUrl ? (
                    <img
                        src={data?.user?.sImageUrl || data?.sCollectionPicUrl}
                        alt={data?.title || data?.sName}
                        className='rounded-xl w-full h-full object-contain object-center'
                        onError={(e) => {
                            e.target.onerror = null; // Prevents infinite loop in case of continuous errors
                            e.target.style.display = 'block'; // Adjust as needed
                            e.target.src =
                                'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Transparent GIF
                        }}
                    />
                ) : (
                    <div className='rounded-xl w-full h-full bg-gradient'></div>
                )}
            </div>

            <h6 className='text-lg lg:text-md capitalize inline-block text-white font-bold py-7 pl-5 group-hover:bg-gradient group-hover:text-transparent group-hover:bg-clip-text sm:py-5'>
                {data?.sBrandName || data?.sName}
            </h6>
            <div className='border-t border-white px-6 py-4 -mx-1 -mb-1 flex justify-between  duration-300 group-hover:border-dark-30'>
                <div className=''>
                    <p className='text-sm text-dark-70 font-bold'>Volume</p>
                    <h6 className='text-base text-white font-bold duration-300 group-hover:text-black'>
                        {toFixedNumber(data?.aNfts?.[0]?.transactions?.[0]?.volume || 0)} USD
                    </h6>
                </div>
                <div className='text-right'>
                    <p className='text-sm text-dark-70 font-bold'>
                        Floor Price
                    </p>
                    <h6 className='text-base text-white font-bold  duration-300 group-hover:text-black'>
                        {toFixedNumber(data?.aNfts?.[0]?.transactions?.[0]?.floorPrice || 0)}{' '}
                        USD
                    </h6>
                </div>
            </div>
        </div>
    );
}

export default CardLarge;
