import { Dialog } from "@headlessui/react";
import React, { useEffect, useRef, useState } from "react";

import {
  useAccount,
  useBalance,
  useContractRead,
  useContractWrite,
  useNetwork,
  useSwitchNetwork,
  useWaitForTransaction,
} from "wagmi";

// COMPONENTS
import Modal from "./Modal";

// IMAGES
import CloseIcon from "../../assets/images/close-icon.svg";
import _contracts_web3 from "../../configs/contracts.web3";
import { CONFIG } from "../../configs/platform.config";
import { notify } from "../../utils/common.helper";
import {
  chainSymbolById,
  formatNumber,
  parseRevertReason,
  toEther,
  toWei,
} from "../../utils/helper";
import QuantityControl from "../FormControl/QuantityControl";
import ButtonLoader from "../Loader/ButtonLoader";

function BuyNowPopup(props) {
  const {
    isPopupOpen,
    setIsPopupOpen,
    currentOwner,
    nftQuantity,
    nftAddress,
    nftId,
    nftChainId,
    nftName,
    nftPrice,
    refetchNft,
    refetchActivity,
    eType,
    nftImage,
    sTokenSymbol,
    sTokenAddress,
    commission,
    setIsOnSale,
  } = props;

  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(nftPrice);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isEnoughBalance, setIsEnoughBalance] = useState(false);
  const [decimals, setDecimals] = useState(18);

  const { switchNetwork } = useSwitchNetwork({
    onSuccess: () => {
      if (sTokenAddress === CONFIG.supportedTokens[0].value) handleBuyNow();
      else setIsEnabled(true);
    },
  });
  const { address } = useAccount();
  const { data: balanceOfUser } = useBalance({
    address,
    chainId: nftChainId,
  });
  const { chain } = useNetwork();
  const modelRef = useRef();

  const {
    data,
    write: buyNow,
    isLoading,
  } = useContractWrite({
    address: _contracts_web3.addresses[nftChainId]?.media,
    abi: _contracts_web3.abis.media,
    functionName: "directBuy",
    account: address,
    value:
      CONFIG.supportedTokens[0].value === sTokenAddress || !sTokenAddress
        ? toWei(totalPrice, decimals)
        : 0,
    onError(error) {
      setIsEnabled(false);
      setIsApproved(false);
      setIsEnoughBalance(false);
      notify("error", parseRevertReason(error.shortMessage));
    },
  });

  const { isLoading: isLoadingTransaction, isSuccess: isSuccessTransaction } =
    useWaitForTransaction({
      hash: data?.hash,
    });

  useEffect(() => {
    if (isSuccessTransaction) {
      setIsPopupOpen(false);
      setIsOnSale(false);
      refetchNft();
      refetchActivity();
      notify("success", "NFT purchased successfully");
    }
  }, [isSuccessTransaction]);

  // Check if ERC20 token is approved for Buy NFT on marketplace
  const {
    isLoading: isGetApproveLoading,
    error,
    isFetching,
  } = useContractRead({
    address: sTokenAddress,
    abi: _contracts_web3.abis.ERC20,
    functionName: "allowance",
    enabled: isEnoughBalance,
    chainId: nftChainId,
    args: [address, _contracts_web3.addresses[nftChainId]?.market],
    onSuccess(data) {
      if (data >= toWei(totalPrice, decimals)) {
        handleBuyNow();
      } else {
        approveWrite();
      }

      setIsEnabled(false);
    },
    onError(error) {
      setIsEnabled(false);
      setIsApproved(false);
      setIsEnoughBalance(false);
    },
  });

  // Check ERC20 Token Balance
  const { isLoading: fetchBalanceLoading, isFetching: balanceFetching } =
    useContractRead({
      address: sTokenAddress,
      abi: _contracts_web3.abis.ERC20,
      functionName: "balanceOf",
      enabled: isEnabled,
      chainId: nftChainId,
      args: [address],
      onSuccess(data) {
        if (data >= toWei(totalPrice, decimals)) {
          setIsEnoughBalance(true);
        } else {
          setIsEnoughBalance(false);
          setIsApproved(false);
          setIsEnabled(false);

          return notify(
            "error",
            `Not enough ${sTokenSymbol} token to buy this NFT`
          );
        }
      },
      onError(error) {
        setIsEnabled(false);
        setIsApproved(false);
        setIsEnoughBalance(false);
      },
    });

  // Approve ERC20 token to Buy NFT on marketplace
  const {
    data: approveData,
    write: approveWrite,
    isLoading: isApproveLoading,
  } = useContractWrite({
    address: sTokenAddress,
    abi: _contracts_web3.abis.ERC20,
    functionName: "approve",
    account: address,
    chainId: nftChainId,
    args: [
      _contracts_web3.addresses[nftChainId]?.market,
      toWei(totalPrice, decimals),
    ],
    onError(error) {
      setIsEnabled(false);
      setIsApproved(false);
      setIsEnoughBalance(false);
      notify("error", parseRevertReason(error.shortMessage));
    },
  });

  const {
    isLoading: isApproveTransactionLoading,
    isSuccess: isApproveTransactionSuccess,
  } = useWaitForTransaction({
    hash: approveData?.hash,
  });

  useEffect(() => {
    if (isApproveTransactionSuccess) {
      handleBuyNow();
    }
  }, [isApproveTransactionSuccess]);

  useEffect(() => {
    setDecimals(decimals || 18);
  }, []);

  const increaseQuantity = (quantity) => {
    if (quantity <= nftQuantity) {
      setQuantity(quantity);
      const price = quantity * toWei(nftPrice, decimals);

      setTotalPrice(toEther(price, decimals));
    }
  };

  const decreaseQuantity = (quantity, index) => {
    if (quantity >= 1) {
      setQuantity(quantity);
      const price = quantity * toWei(nftPrice, decimals);
      setTotalPrice(toEther(price, decimals));
    }
  };

  const handleSubmit = async () => {
    if (chain.id !== nftChainId) {
      await switchNetwork(nftChainId);
    } else if (
      !sTokenAddress ||
      sTokenAddress === CONFIG.supportedTokens[0].value
    ) {
      handleBuyNow();
    } else {
      setIsEnabled(true);
    }
  };

  function handleBuyNow() {
    const price = toWei(totalPrice, decimals);
    if (price > balanceOfUser.value)
      return notify("error", "Not enough balance to buy this NFT");

    buyNow({
      args: [
        nftAddress,
        nftId,
        currentOwner,
        quantity,
        price,
        commission * 100,
      ],
    });
  }

  return (
    <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
      <div className="flex justify-between items-start">
        <Dialog.Title
          as="h3"
          className="text-3xl font-black font-monserrat text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2"
        >
          Buy Now
        </Dialog.Title>
        <button
          className="focus-visible:outline-none"
          onClick={() => setIsPopupOpen(false)}
        >
          <img src={CloseIcon} alt="CloseIcon" className="w-7" />
        </button>
      </div>
      <div className="mt-10">
        <div className="flex items-center gap-4 border-b border-dark-20 pb-6 xs:flex-wrap">
          <div className="relative w-24 h-24 rounded-2xl overflow-hidden sm:w-16 sm:h-16">
            {eType === "image" ? (
              <img
                src={`${nftImage}`}
                alt="NftDetailImg"
                className="absolute left-0 top-0 w-full h-full object-cover object-center"
              />
            ) : eType === "model" ? (
              <model-viewer
                alt={nftName}
                ar
                src={`${nftImage}`}
                poster={`${nftImage}`}
                shadow-intensity="1"
                ref={(ref) => {
                  modelRef.current = ref;
                }}
                touch-action="pan-y"
                class="absolute w-full h-full left-0 top-0 object-cover object-center"
                autoplay
              ></model-viewer>
            ) : eType === "video" ? (
              <video
                src={`${nftImage}`}
                className="absolute left-0 top-0 object-cover object-center h-full"
              />
            ) : null}
          </div>
          <div className="flex-1">
            <h5 className="text-2xl font-bold text-black sm:text-lg">
              {nftName}
            </h5>
            <h6 className="text-xl text-dark-70 mt-2 sm:text-md">
              {formatNumber(nftPrice)}{" "}
              {sTokenSymbol || chainSymbolById(nftChainId)}
            </h6>
          </div>
          <div className="flex items-center gap-3 xs:w-full">
            <p className="text-2xl font-bold text-dark-70  sm:text-lg">Qty:</p>
            <QuantityControl
              quantity={quantity}
              increaseQuantity={increaseQuantity}
              decreaseQuantity={decreaseQuantity}
            />
          </div>
        </div>
        <div className="flex justify-between py-6">
          <h5 className="text-2xl font-bold text-black sm:text-lg">Total:</h5>
          <h5 className="text-2xl font-bold text-black sm:text-lg">
            {formatNumber(totalPrice)}{" "}
            {sTokenSymbol || chainSymbolById(nftChainId)}
          </h5>
        </div>
        <button
          className="btn-secondary w-full"
          onClick={handleSubmit}
          disabled={
            isLoading ||
            isLoadingTransaction ||
            isGetApproveLoading ||
            isApproveLoading ||
            isApproveTransactionLoading ||
            isFetching ||
            balanceFetching ||
            fetchBalanceLoading
          }
        >
          {(isLoading ||
            isLoadingTransaction ||
            isGetApproveLoading ||
            isApproveLoading ||
            isApproveTransactionLoading ||
            isFetching ||
            balanceFetching ||
            fetchBalanceLoading) && <ButtonLoader />}
          PAY NOW
        </button>
      </div>
    </Modal>
  );
}

export default BuyNowPopup;
