import { Listbox, Transition } from '@headlessui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { Fragment, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { getCategory } from '../../api/adapters/asset-common';
import { getUserCollection } from '../../api/adapters/collection';
import { getNfts, getUserActivity } from '../../api/adapters/nft';
import { follow, getUserProfile, unFollow } from '../../api/adapters/user';
import { CONFIG } from '../../configs/platform.config';
import CHAINS_WEB3 from '../../configs/web3-chains';
import { toggleLoader } from '../../redux/actions/loader';
import { notify } from '../../utils/common.helper';
import { getCookie, getToken } from '../../utils/cookies.helper';
import {
    chainSymbolById,
    copyToClipboard,
    formatNumber,
    formatNumberWithK,
    parseDate,
    trimEthereumAddress,
} from '../../utils/helper';

// COMPONENTS
import CardMedium from '../../components/CardMedium/CardMedium';
import CollectionCard from '../../components/CollectionCard/CollectionCard';
import Filter from '../../components/Filter/Filter';
import FollowerFollowing from '../../components/FollowerFollowing/FollowerFollowing';
import FollowingFollowersPopup from '../../components/Modal/FollowingFollowersPopup';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import ProfileBanner from '../../components/ProfileBanner/ProfileBanner';

// IMAGES
import BurnedIcon from '../../assets/images/burned-icon.svg';
import Cart2Icon from '../../assets/images/cart-icon2.svg';
import ClearIcon from '../../assets/images/clear-icon.svg';
import CollapseIcon from '../../assets/images/collapse-arrow.svg';
import DiscordIcon from '../../assets/images/discord-icon-2.svg';
import EmailIcon from '../../assets/images/email-icon-2.svg';
import ExportIcon from '../../assets/images/export-icon.svg';
import FilterIcon from '../../assets/images/filter-icon.svg';
import InstagramIcon from '../../assets/images/instagram-icon-2.svg';
// import LikeIcon from '../../assets/images/like-icon.svg';
import LinkIcon from '../../assets/images/link-icon.svg';
import ListedIcon from '../../assets/images/listed-icon.svg';
import OffersIcon from '../../assets/images/offers-icon.svg';
import OMI from '../../assets/images/omi-icon.svg';
// import ProductImg1 from '../../assets/images/product-img-1.png';
import BSC from '../../assets/images/Binance-icon.svg';
import BOBA from '../../assets/images/Boba-icon.svg';
import ETH from '../../assets/images/Ethereum-icon.svg';
import MATIC from '../../assets/images/Polygon-icon.svg';
import LikeIcon from '../../assets/images/like-icon.svg';
import MintIcon from '../../assets/images/mint-icon.svg';
import TransferredIcon from '../../assets/images/transferred-icon.svg';
import TwitterIcon from '../../assets/images/twitter-icon.svg';
import USDC from '../../assets/images/usd-icon.svg';
import VerifyIcon from '../../assets/images/verify-icon.svg';
import WebsiteIcon from '../../assets/images/website-icon.svg';
import { updateUser } from '../../redux/actions/user';
import { shortBy } from '../../constants/platform-constants';

function OtherProfile() {
    const eventIcon = {
        Sold: Cart2Icon,
        List: ListedIcon,
        'Timed Auction': ListedIcon,
        Bid: OffersIcon,
        Liked: LikeIcon,
        Mint: MintIcon,
        Burn: BurnedIcon,
        Transfer: TransferredIcon,
    };

    const chainIcon = {
        ETH: ETH,
        BNB: BSC,
        MATIC: MATIC,
        USDC: USDC,
        BOBA: BOBA,
        OMI: OMI,
    };
    const [showFilter, seShowFilter] = useState(false);
    const [selectedShortBy, setSelectedShortBy] = useState({});
    const [tabsActive, setTabsActive] = useState('nfts');
    const [isFollowsPopupOpen, setIsFollowsPopupOpen] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [filter, setFilter] = useState([]);
    const [isFollowerPop, setIsFollowerPop] = useState();
    const [filters, setFilters] = useState({
        status: 'All',
        blockChain: 'All',
        type: 'All',
        token: 'All',
        categories: [],
        price: {
            min: '',
            max: '',
        },
    });
    const [imageUrls, setImageUrls] = useState([]);

    const { username } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const modelRef = useRef();
    const { isConnected } = useAccount();
    const user = useSelector((state) => state.user);

    if (username === getCookie('_userName')) navigate('/my-nft');

    const { data: category } = useQuery({
        queryKey: ['category'],
        queryFn: () =>
            getCategory().then((res) => {
                res.data.data.forEach((element) => {
                    if (!categoryList.includes(element._id))
                        setCategoryList((prev) => [...prev, element._id]);
                });
                return res.data.data;
            }),
    });

    const {
        data: userDetail,
        error,
        refetch: refetchUserDetail,
    } = useQuery({
        queryKey: ['userDetail', username],
        queryFn: () =>
            getUserProfile(username).then((res) => {
                setTimeout(() => {
                    dispatch(toggleLoader(false));
                }, 300);
                return res.data.data;
            }),
        retry: false,
    });

    const { data: collections } = useQuery({
        queryKey: ['collections'],
        queryFn: () =>
            getUserCollection(userDetail?.oUser._id).then(
                (res) => res.data.data
            ),
        enabled: !!userDetail?.oUser?._id,
        retry: true,
    });

    const { data: nfts, refetch: refetchNft } = useQuery({
        queryKey: [
            'nfts',
            selectedShortBy.value,
            filters.categories.length,
            filters.status,
            filters.blockChain,
            filters.type,
            filters.token,
            tabsActive,
        ],
        queryFn: () =>
            getNfts({
                sUserName: username,
                sSortType: selectedShortBy.value || undefined,
                oFilters: {
                    ...filters,
                    categories: filters.categories.length
                        ? filters.categories
                        : categoryList,
                    // status: 'Fixed Sale',
                },
                start: 0,
                length: 1000,
            }).then((res) => res.data.data),
        enabled: !!userDetail?.oUser?._id,
    });

    const { data: userActivity } = useQuery({
        queryKey: ['userActivity', filter, username],
        queryFn: () =>
            getUserActivity({
                oFilters: filter,
                sUserName: userDetail?.oUser?.sUserName,
            }).then((res) => {
                setTimeout(() => {
                    dispatch(toggleLoader(false));
                }, 300);

                if (res.data.data) {
                    const urls = res.data.data.map(item =>
                        item?.sNftCloudinaryLink || `${res.data.data?.bIsMigrated ? CONFIG.sPinataIpfs.Uri : CONFIG.sFileBaseUri}${item?.aNFT?.sImageHash}`
                    );
                    setImageUrls(urls);
                }
                return res.data.data;
            }),
        enabled: !!userDetail,
    });

    const { mutate: mutateFollow, isPending: mutateFollowPending } = useMutation({
        mutationFn: () => follow({ sFollowingId: userDetail?.oUser?._id }),
        onSuccess: (data) => {
            refetchUserDetail();
            const newArr = [...user.aFollowing, userDetail?.oUser?._id];
            dispatch(updateUser({ ...user, aFollowing: newArr }));
        },
        onError: (error) => {
            notify('error', error);
        },
    });
    const { mutate: mutateUnFollow, isPending: mutateUnFollowPending } = useMutation({
        mutationFn: () => unFollow({ sFollowingId: userDetail?.oUser?._id }),
        onSuccess: (data) => {
            refetchUserDetail();

            const newArr = user.aFollowing.filter(
                (following) => following !== userDetail?.oUser?._id
            );

            dispatch(
                updateUser({
                    ...user,
                    aFollowing: newArr,
                })
            );
        },
        onError: (error) => {
            console.log(error);
            notify('error', error);
        },
    });

    const tabsList = [
        { name: 'NFTs', key: 'nfts', count: nfts?.length || '' },
        { name: 'Collection', key: 'collection', count: collections?.length },
        { name: 'Activity', key: 'Activity', count: '' },
    ];
    const filterList = [
        {
            id: 'List',
            name: 'Listings',
        },
        {
            id: 2,
            name: 'Sales',
        },
        {
            id: 3,
            name: 'Bids',
        },
        {
            id: 4,
            name: 'Transfer',
        },
    ];
    const handleClear = () => {
        setFilters({
            status: 'All',
            blockChain: 'All',
            type: 'All',
            categories: [],
            price: {
                min: '',
                max: '',
            },
        });
        setSelectedShortBy({});
    };
    if (error?.status === 404) {
        dispatch(toggleLoader(false));
        return navigate('/404');
    }

    const handleError = (index) => {
        const newImageUrls = [...imageUrls];
        newImageUrls[index] = `${CONFIG.sPinataIpfsUri}${userActivity[index]?.aNFT?.sImageHash}`; // Your fallback image URL
        setImageUrls(newImageUrls);
    };

    return (
        <section>
            <ProfileBanner
                bannerImage={userDetail?.oUser?.sCoverPicUrl || ''}
                profileImage={userDetail?.oUser?.sProfilePicUrl || ''}
            />
            <div className='container pt-20 pb-20 sm:pt-14'>
                <div className='flex gap-10 justify-between border-b-2 border-b-dark-20 pb-14 md:flex-col sm:pb-10'>
                    <div className='max-w-3xl md:max-w-none'>
                        <h1 className='main-heading-h1 text-darkGray'>
                            {userDetail?.oUser?.sUserName}
                            {userDetail?.oUser?.isUserVerified && (
                                <img
                                    src={VerifyIcon}
                                    alt='VerifyIcon'
                                    className='w-11 inline-block ml-2'
                                />
                            )}
                        </h1>
                        <div className='my-8 sm:my-5 sm:flex sm:gap-2'>
                            <button
                                className='btn-secondary bg-purple border-purple text-black text-base mr-5 md:text-sm sm:text-xs sm:mr-0'
                                onClick={async (e) => {
                                    const data = await copyToClipboard(
                                        userDetail?.oUser?.sWalletAddress
                                    );
                                    if (data)
                                        notify(
                                            'success',
                                            'Wallet address copied to clipboard'
                                        );
                                }}
                            >
                                {userDetail?.oUser?.sWalletAddress &&
                                    trimEthereumAddress(
                                        userDetail?.oUser?.sWalletAddress,
                                        5
                                    )}
                                <img
                                    src={LinkIcon}
                                    alt='LinkIcon'
                                    className='ml-2'
                                />
                            </button>
                            {userDetail?.oUser?.aFollowers.length &&
                                userDetail?.oUser?.aFollowers.includes(user.id) ? (
                                <button
                                    className='btn-border'
                                    onClick={(e) => {
                                        e.preventDefault();

                                        mutateUnFollow()
                                    }}
                                    disabled={mutateUnFollowPending}
                                >
                                    Unfollow
                                </button>
                            ) : (
                                <button
                                    className='btn-secondary'
                                    onClick={(e) => {
                                        if (!getToken() || !isConnected)
                                            return notify(
                                                'error',
                                                'Please connect your wallet!'
                                            );

                                        mutateFollow();
                                    }}
                                    disabled={mutateFollowPending}
                                >
                                    Follow
                                </button>
                            )}
                        </div>
                        <div className='flex gap-20 mb-10 lg:gap-16 sm:gap-12 sm:mb-5'>
                            <FollowerFollowing
                                aFollowers={userDetail?.oUser?.aFollowers}
                                aFollowing={userDetail?.oUser?.aFollowing}
                                sUserName={username}
                                refetchUser={refetchUserDetail}
                            />
                        </div>
                        {userDetail?.oUser?.sBio && (
                            <div>
                                <h6 className='text-sm text-black font-bold mb-3'>
                                    BIO
                                </h6>
                                <p className='text-sm text-dark-90'>
                                    {userDetail?.oUser?.sBio}
                                </p>
                            </div>
                        )}
                    </div>
                    <div>
                        <div className='flex gap-4 justify-end sm:flex-wrap sm:justify-start'>
                            <Link
                                to=''
                                target='_blank'
                                className='group text-center xs:basis-[29%]'
                            >
                                <span className='bg-dark-10 p-2 rounded-xl inline-block duration-300 group-hover:bg-black'>
                                    <img
                                        src={TwitterIcon}
                                        alt='TwitterIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </span>
                                <span className='block text-xxs text-dark-70 duration-300 group-hover:text-black'>
                                    Twitter
                                </span>
                            </Link>
                            <Link
                                to=''
                                target='_blank'
                                className='group text-center xs:basis-[29%]'
                            >
                                <span className='bg-dark-10 p-2 rounded-xl inline-block duration-300 group-hover:bg-black'>
                                    <img
                                        src={InstagramIcon}
                                        alt='InstagramIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </span>
                                <span className='block text-xxs text-dark-70 duration-300 group-hover:text-black'>
                                    Instagram
                                </span>
                            </Link>
                            <Link
                                to=''
                                target='_blank'
                                className='group text-center xs:basis-[29%]'
                            >
                                <span className='bg-dark-10 p-2 rounded-xl inline-block duration-300 group-hover:bg-black'>
                                    <img
                                        src={DiscordIcon}
                                        alt='DiscordIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </span>
                                <span className='block text-xxs text-dark-70 duration-300 group-hover:text-black'>
                                    Discord
                                </span>
                            </Link>
                            <Link
                                to=''
                                target='_blank'
                                className='group text-center xs:basis-[29%]'
                            >
                                <span className='bg-dark-10 p-2 rounded-xl inline-block duration-300 group-hover:bg-black'>
                                    <img
                                        src={WebsiteIcon}
                                        alt='WebsiteIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </span>
                                <span className='block text-xxs text-dark-70 duration-300 group-hover:text-black'>
                                    Website
                                </span>
                            </Link>
                            <Link
                                to=''
                                target='_blank'
                                className='group text-center xs:basis-[29%]'
                            >
                                <span className='bg-dark-10 p-2 rounded-xl inline-block duration-300 group-hover:bg-black'>
                                    <img
                                        src={EmailIcon}
                                        alt='EmailIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </span>
                                <span className='block text-xxs text-dark-70 duration-300 group-hover:text-black'>
                                    Email
                                </span>
                            </Link>
                            <button
                                className='group text-center xs:basis-[29%]'
                                onClick={() => {
                                    const data = copyToClipboard(
                                        `${window.location.origin}/user/${username}`
                                    );

                                    if (data)
                                        notify(
                                            'success',
                                            'Link copied to clipboard'
                                        );
                                }}
                            >
                                <span className='bg-dark-10 p-2 rounded-xl inline-block duration-300 group-hover:bg-black'>
                                    <img
                                        src={LinkIcon}
                                        alt='LinkIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </span>
                                <span className='block text-xxs text-dark-70 duration-300 group-hover:text-black'>
                                    Copy
                                </span>
                            </button>
                        </div>
                        <div className='border border-dark-30 rounded-xl p-4 px-7 mt-10 min-w-[420px] md:min-w-full'>
                            <div className='flex justify-between py-2'>
                                <p className='text-base text-dark-60 font-medium sm:text-sm'>
                                    Floor
                                </p>
                                <p className='text-base text-black font-bold sm:text-sm'>
                                    {userDetail?.aTransactions[0]?.floorPrice.toFixed(
                                        7
                                    ) || 0}{' '}
                                    USD
                                </p>
                            </div>
                            <div className='flex justify-between py-2'>
                                <p className='text-base text-dark-60 font-medium sm:text-sm'>
                                    Volume
                                </p>
                                <p className='text-base text-black font-bold sm:text-sm'>
                                    {userDetail?.aTransactions[0]?.volume.toFixed(
                                        7
                                    ) || 0}{' '}
                                    USD
                                </p>
                            </div>
                            <div className='flex justify-between py-2'>
                                <p className='text-base text-dark-60 font-medium sm:text-sm'>
                                    Items
                                </p>
                                <p className='text-base text-black font-bold sm:text-sm'>
                                    {formatNumberWithK(
                                        userDetail?.nTotalNfts || 0
                                    )}
                                </p>
                            </div>
                            <div className='flex justify-between py-2'>
                                <p className='text-base text-dark-60 font-medium sm:text-sm'>
                                    Creator fee
                                </p>
                                <p className='text-base text-black font-bold sm:text-sm'>
                                    0%
                                </p>
                            </div>
                            <div className='flex justify-between py-2'>
                                <p className='text-base text-dark-60 font-medium sm:text-sm'>
                                    Blockchain
                                </p>
                                <p className='text-base text-black font-bold sm:text-sm'>
                                    -------
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='py-14 lg:py-12 sm:py-10'>
                    <ul className='flex justify-center flex-wrap gap-8 md:gap-5 md:gap-y-2'>
                        {tabsList.map((item, i) => {
                            if (
                                item.key === 'collection' &&
                                !collections?.length
                            )
                                return null;

                            return (
                                <li
                                    key={i}
                                    onClick={() => setTabsActive(item.key)}
                                >
                                    <span
                                        className={`text-base inline-block font-bold text-dark-60 cursor-pointer duration-300 relative after:h-[3px] after:absolute after:-bottom-2 after:left-0 after:duration-300 after:bg-gradient after:opacity-0 after:w-full hover:text-black hover:after:opacity-100 md:text-sm ${tabsActive === item.key
                                            ? '!text-black after:opacity-100'
                                            : ''
                                            }`}
                                    >
                                        {item.name}
                                        {item.count && (
                                            <span className='ml-2 px-2 py-1.5 text-xs text-white inline-block bg-black rounded-lg font-bold sm:text-xxs sm:py-1 sm:px-1.5 sm:align-bottom'>
                                                {item.count}
                                            </span>
                                        )}
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                {tabsActive === 'nfts' && (
                    <div className='flex md:flex-wrap'>
                        <div className='relative pt-16 z-10 md:w-full md:pt-0'>
                            <div className='flex gap-5 absolute top-0'>
                                <button
                                    onClick={() => seShowFilter(!showFilter)}
                                    className={`group flex-shrink-0  p-3.5 rounded duration-300 hover:bg-black xs:p-2.5 ${showFilter ? 'bg-black' : 'bg-dark-10'
                                        }`}
                                >
                                    <img
                                        src={FilterIcon}
                                        alt='FilterIcon'
                                        className={`duration-300 group-hover:invert ${showFilter ? 'invert' : ''
                                            }`}
                                    />
                                </button>
                                <button
                                    className='group flex-shrink-0 bg-dark-10 p-3.5 rounded duration-300 hover:bg-black xs:p-2.5'
                                    onClick={handleClear}
                                >
                                    <img
                                        src={ClearIcon}
                                        alt='ClearIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </button>
                            </div>
                            {showFilter && (
                                <Filter
                                    category={category}
                                    filters={filters}
                                    setFilters={setFilters}
                                    categoryIdList={categoryList}
                                    refetch={refetchNft}
                                />
                            )}
                        </div>
                        {!nfts?.length && (
                            <div className='w-full mt-24 -mr-10 h-[430px] flex items-center justify-center sm:h-60'>
                                <NoDataFound />
                            </div>
                        )}
                        <div
                            className={`flex-1 relative grid grid-cols-4 gap-x-5 gap-y-10 pt-20 xl:grid-cols-3 md:grid-cols-2 sm:flex sm:flex-wrap sm:gap-y-7 xs:pt-16 ${showFilter
                                ? '!grid-cols-3 lg:!grid-cols-2 sm:lg:!grid-cols-1'
                                : ''
                                } `}
                        >
                            <div className='absolute top-0 right-0 z-10 xs:relative xs:w-full'>
                                <Listbox
                                    value={selectedShortBy}
                                    onChange={(e) => {
                                        setSelectedShortBy({
                                            name: e.name,
                                            value: e.value,
                                        });
                                    }}
                                >
                                    <div className='relative'>
                                        <Listbox.Button className='w-72 flex justify-between items-center relative cursor-pointer rounded-xl bg-dark-10 border border-dark-30 py-3.5 px-4 text-left text-sm font-bold text-dark-60 focus:outline-none xs:w-full aria-[expanded=true]:border-dark-70'>
                                            <span className='block truncate'>
                                                {selectedShortBy?.name
                                                    ? selectedShortBy?.name
                                                    : 'Sort by'}
                                            </span>
                                            <span className='pointer-events-none inset-y-0 pr-0'>
                                                <img
                                                    src={CollapseIcon}
                                                    alt='CollapseIcon'
                                                    className='mr-0'
                                                ></img>
                                            </span>
                                        </Listbox.Button>
                                        <Transition
                                            as={Fragment}
                                            enter='transition ease-out duration-100 group-[.mobile-active]:h-0'
                                            enterFrom='transform opacity-0 translate-y-2 group-[.mobile-active]:h-0'
                                            enterTo='transform opacity-100 translate-y-0  group-[.mobile-active]:h-auto'
                                            leave='transition ease-in duration-75'
                                            leaveFrom='transform opacity-100 translate-y-0'
                                            leaveTo='transform opacity-0 translate-y-2'
                                        >
                                            <Listbox.Options className='dropdown mt-1 w-full text-base focus:outline-none sm:text-sm'>
                                                {shortBy.map(
                                                    (
                                                        shortItem,
                                                        shortItemIdx
                                                    ) => (
                                                        <Listbox.Option
                                                            key={shortItemIdx}
                                                            className={({
                                                                active,
                                                            }) =>
                                                                `relative text-base font-medium cursor-pointer py-3 pl-8 pr-4 hover:bg-primary/20 ${active
                                                                    ? 'bg-primary/20'
                                                                    : ''
                                                                }`
                                                            }
                                                            value={shortItem}
                                                        >
                                                            {({ selected }) => (
                                                                <>
                                                                    <span className='block truncate'>
                                                                        {
                                                                            shortItem.name
                                                                        }
                                                                    </span>
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    )
                                                )}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </Listbox>
                            </div>
                            {nfts?.length
                                ? nfts.map((item, i) => {
                                    return (
                                        <div key={i} className='sm:w-full'>
                                            <CardMedium
                                                data={item}
                                                chainId={item.nChainId}
                                            />
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>
                )}
                {tabsActive === 'collection' && (
                    <div className=''>
                        <div className='relative grid grid-cols-3 gap-x-5 gap-y-5 md:grid-cols-2 sm:flex sm:flex-wrap'>
                            {collections &&
                                collections.map((item, i) => {
                                    return (
                                        <div key={i} className='sm:w-full'>
                                            <CollectionCard
                                                data={{
                                                    ...item,
                                                    profileImage:
                                                        userDetail?.oUser
                                                            ?.sProfilePicUrl,
                                                }}
                                                isOwner={false}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
                {tabsActive === 'Activity' && (
                    <div className='flex xl:flex-col'>
                        <div className='w-1/6 xl:w-full'>
                            <h6 className='text-lg font-bold text-black mb-5'>
                                Event Type
                            </h6>
                            <div className='flex flex-col flex-wrap xl:flex-row xl:gap-x-5 xl:mb-5'>
                                {filterList.map((option) => (
                                    <div
                                        key={option.id}
                                        className='flex items-center mb-2'
                                    >
                                        <input
                                            id={option.name}
                                            name={option.name}
                                            type='checkbox'
                                            className='h-[18px] w-[18px] rounded border-2 border-dark-80 text-primary focus:ring-transparent'
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setFilter([
                                                        ...filter,
                                                        option.name,
                                                    ]);
                                                } else {
                                                    setFilter(
                                                        filter.filter(
                                                            (item) =>
                                                                item !==
                                                                option.name
                                                        )
                                                    );
                                                }
                                            }}
                                        />
                                        <label
                                            htmlFor={option.name}
                                            className='ml-3 min-w-0 flex-1 text-base font-medium text-dark-80'
                                        >
                                            {option.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='flex-1'>
                            <div className='flex flex-col gap-y-2.5'>
                                <div className='flex gap-4 px-4 pb-3 text-md text-dark-60 font-medium xl:hidden'>
                                    <div className='w-[15%]'>
                                        <p>Event</p>
                                    </div>
                                    <div className='w-[17%]'>
                                        <p>Item</p>
                                    </div>
                                    <div className='w-[15%]'>
                                        <p>Price</p>
                                    </div>
                                    <div className='w-[10%]'>
                                        <p>Quantity</p>
                                    </div>
                                    <div className='w-[15%]'>
                                        <p>From</p>
                                    </div>
                                    <div className='w-[15%]'>
                                        <p>To</p>
                                    </div>
                                    <div className='w-[13%]'>
                                        <p>Time</p>
                                    </div>
                                </div>
                                {userActivity?.length ? (
                                    userActivity?.map((item, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className='flex items-center gap-4 p-4 border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0'
                                            >
                                                <div className='w-[15%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                                    <div className='hidden w-1/3 text-sm text-dark-60 font-medium lg:block'>
                                                        <p>Event</p>
                                                    </div>
                                                    <div className='flex items-center gap-3'>
                                                        <div className='p-1.5 rounded-full bg-dark-10'>
                                                            <img
                                                                src={
                                                                    eventIcon[
                                                                    item
                                                                        .eBidStatus
                                                                    ]
                                                                }
                                                                alt='eventIcon'
                                                            />
                                                        </div>
                                                        <p>{item.eBidStatus}</p>
                                                    </div>
                                                </div>
                                                <div className='w-[17%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                                    <div className='hidden w-1/3 text-sm text-dark-60 font-medium lg:block'>
                                                        <p>Item</p>
                                                    </div>
                                                    <div className='flex items-center gap-4'>
                                                        <div className='w-16 h-16 flex-shrink-0 relative xl:w-12 xl:h-12'>
                                                            <Link
                                                                to={`/nft/${item?.aNFT?._id}`}
                                                                className='absolute top-0 left-0 w-full h-full z-10'
                                                            ></Link>
                                                            {item.aNFT.eType ===
                                                                'image' ? (
                                                                <img
                                                                    src={
                                                                        imageUrls[i]
                                                                    }
                                                                    alt='ProductImg1'
                                                                    className='rounded absolute left-0 top-0 object-cover object-center w-full h-full'
                                                                    onError={() => handleError(i)}
                                                                />
                                                            ) : item.aNFT
                                                                .eType ===
                                                                'video' ? (
                                                                <video
                                                                    src={
                                                                        imageUrls[i]
                                                                    }
                                                                    alt='ProductImg1'
                                                                    className='rounded absolute left-0 top-0 object-cover object-center w-full h-full'
                                                                    onError={() => handleError(i)}
                                                                />
                                                            ) : item.aNFT
                                                                .eType ===
                                                                'model' ? (
                                                                <model-viewer
                                                                    alt={
                                                                        item
                                                                            .aNFT
                                                                            .sName
                                                                    }
                                                                    src={
                                                                        imageUrls[i]
                                                                    }
                                                                    ar
                                                                    autoplay
                                                                    poster={
                                                                        imageUrls[i]
                                                                    }
                                                                    shadow-intensity='1'
                                                                    camera-controls={
                                                                        false
                                                                    }
                                                                    ref={(
                                                                        ref
                                                                    ) => {
                                                                        modelRef.current =
                                                                            ref;
                                                                    }}
                                                                    touch-action='pan-y'
                                                                    class='rounded absolute left-0 top-0 object-cover object-center w-full h-full'
                                                                    onError={() => handleError(i)}
                                                                ></model-viewer>
                                                            ) : null}
                                                        </div>
                                                        <p>{item.aNFT.sName}</p>
                                                    </div>
                                                </div>
                                                <div className='w-[15%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                                    <div className='hidden w-1/3 text-sm text-dark-60 font-medium lg:block'>
                                                        <p>Price</p>
                                                    </div>
                                                    <div className='flex items-center gap-2.5'>
                                                        <div className=''>
                                                            <img
                                                                src={
                                                                    chainIcon[
                                                                    item
                                                                        .sTokenSymbol
                                                                    ] ||
                                                                    chainIcon[
                                                                    chainSymbolById(
                                                                        item
                                                                            ?.aNFT
                                                                            ?.nChainId
                                                                    )
                                                                    ]
                                                                }
                                                                alt='OmiIcon'
                                                                className='w-6 h-6'
                                                            />
                                                        </div>
                                                        <p>
                                                            {item.nBidPrice}{' '}
                                                            {item.sTokenSymbol ||
                                                                chainSymbolById(
                                                                    item.aNFT
                                                                        .nChainId
                                                                )}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='w-[10%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                                    <div className='hidden w-1/3 text-sm text-dark-60 font-medium lg:block'>
                                                        <p>Quantity</p>
                                                    </div>
                                                    <p>
                                                        {formatNumber(
                                                            item.nQuantity
                                                        )}
                                                    </p>
                                                </div>
                                                <div className='w-[15%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                                    <div className='hidden w-1/3 text-sm text-dark-60 font-medium lg:block'>
                                                        <p>From</p>
                                                    </div>
                                                    <div className='flex items-center gap-4'>
                                                        {item.eBidStatus !==
                                                            'Mint' && (
                                                                <div className='rounded-full w-11 h-11 relative flex-shrink-0'>
                                                                    {item?.aFrom
                                                                        ?.sProfilePicUrl ? (
                                                                        <img
                                                                            src={
                                                                                item
                                                                                    .aFrom
                                                                                    ?.sProfilePicUrl
                                                                            }
                                                                            alt='collectionProfile'
                                                                            onError={(
                                                                                e
                                                                            ) => {
                                                                                e.target.onerror =
                                                                                    null; // Prevents infinite loop in case of continuous errors
                                                                                e.target.style.display =
                                                                                    'block'; // Adjust as needed
                                                                                e.target.src =
                                                                                    'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Transparent GIF
                                                                            }}
                                                                            className='rounded-full absolute left-0 top-0 w-full h-full object-cover object-center'
                                                                        />
                                                                    ) : (
                                                                        <div className='rounded-full absolute left-0 top-0 w-full h-full object-cover object-center bg-gradient'></div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        <p>
                                                            <Link
                                                                to={`/user/${item.aFrom?.sUserName}`}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        item.eBidStatus ===
                                                                        'Mint'
                                                                    )
                                                                        e.preventDefault();
                                                                }}
                                                            >
                                                                {item.eBidStatus ===
                                                                    'Mint'
                                                                    ? 'N/A'
                                                                    : item.aFrom
                                                                        ?.sUserName}
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='w-[15%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                                    <div className='hidden w-1/3 text-sm text-dark-60 font-medium lg:block'>
                                                        <p>To</p>
                                                    </div>
                                                    <div className='flex items-center gap-4'>
                                                        <div className='rounded-full w-11 h-11 relative flex-shrink-0'>
                                                            {(item.eBidStatus !==
                                                                'Burn' &&
                                                                item?.eBidStatus ===
                                                                'Mint' &&
                                                                item.aFrom
                                                                    ?.sProfilePicUrl) ||
                                                                item.aTo
                                                                    ?.sProfilePicUrl ? (
                                                                <img
                                                                    src={
                                                                        item?.eBidStatus ===
                                                                            'Burn'
                                                                            ? ''
                                                                            : item.eBidStatus ===
                                                                                'Mint'
                                                                                ? item
                                                                                    .aFrom
                                                                                    ?.sProfilePicUrl
                                                                                : item
                                                                                    .aTo
                                                                                    ?.sProfilePicUrl
                                                                    }
                                                                    alt='collectionProfile'
                                                                    onError={(
                                                                        e
                                                                    ) => {
                                                                        e.target.onerror =
                                                                            null; // Prevents infinite loop in case of continuous errors
                                                                        e.target.style.display =
                                                                            'block'; // Adjust as needed
                                                                        e.target.src =
                                                                            'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Transparent GIF
                                                                    }}
                                                                    className='rounded-full absolute left-0 top-0 w-full h-full object-cover object-center'
                                                                />
                                                            ) : (
                                                                <div className='rounded-full absolute left-0 top-0 w-full h-full object-cover object-center bg-gradient'></div>
                                                            )}
                                                        </div>

                                                        <p>
                                                            <Link
                                                                to={`/user/${item.eBidStatus ===
                                                                    'Mint'
                                                                    ? item
                                                                        .aFrom
                                                                        ?.sUserName
                                                                    : item
                                                                        .aTo
                                                                        ?.sUserName
                                                                    }`}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        item.eBidStatus ===
                                                                        'Burn'
                                                                    )
                                                                        e.preventDefault();
                                                                }}
                                                            >
                                                                {item.eBidStatus ===
                                                                    'Mint'
                                                                    ? item.aFrom
                                                                        ?.sUserName
                                                                    : item?.eBidStatus ===
                                                                        'Burn'
                                                                        ? 'N/A'
                                                                        : item.aTo
                                                                            ?.sUserName}
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='w-[13%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                                    <div className='hidden w-1/3 text-sm text-dark-60 font-medium lg:block'>
                                                        <p>Time</p>
                                                    </div>
                                                    <div className='flex items-center gap-4'>
                                                        <p>
                                                            {parseDate(
                                                                item.dCreatedAt
                                                            )}
                                                        </p>
                                                        {item?.eBidStatus !==
                                                            'Liked' && (
                                                                <div className=''>
                                                                    <a
                                                                        href={`${CHAINS_WEB3[
                                                                            item
                                                                                .aNFT
                                                                                ?.nChainId
                                                                        ]
                                                                            .blockExplorerUrls
                                                                            }/tx/${item.sTransactionHash
                                                                            }`}
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                    >
                                                                        <img
                                                                            src={
                                                                                ExportIcon
                                                                            }
                                                                            alt='ExportIcon'
                                                                            className='w-6 h-6'
                                                                        />
                                                                    </a>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className='w-full mt-24 -mr-10 h-[430px] flex items-center justify-center sm:h-60'>
                                        <NoDataFound />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <FollowingFollowersPopup
                    isPopupOpen={isFollowsPopupOpen}
                    setIsPopupOpen={setIsFollowsPopupOpen}
                />
            </div>
        </section>
    );
}

export default OtherProfile;
