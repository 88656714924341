import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory } from '../../api/adapters/asset-common';
import { getBrandNft } from '../../api/adapters/brand';
import { toggleLoader } from '../../redux/actions/loader';

// COMPONENTS
import { getCategoryNFTs } from '../../api/adapters/nft';
import CardLarge from '../../components/CardLarge/CardLarge';
import CardMedium from '../../components/CardMedium/CardMedium';
import CreateSellNfts from '../../components/CreateSellNfts/CreateSellNfts';
import Loader from '../../components/Loader/Loader';
import NoDataFound from '../../components/NoDataFound/NoDataFound';

// IMAGES

function AllBrands() {
    const [categoryActive, setCategoryActive] = useState('all-nfts');

    const queryParams = new URLSearchParams(window.location.search);
    const category = queryParams.get('category');

    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader);

    const { data: brandNfts } = useQuery({
        queryKey: ['brandNfts'],
        queryFn: () =>
            getBrandNft({
                start: 0,
                length: 1000,
            }).then((res) => {
                setTimeout(() => {
                    dispatch(toggleLoader(false));
                }, 300);
                return res.data.data;
            }),
    });

    const { data: categories } = useQuery({
        queryKey: ['categories'],
        queryFn: () =>
            getCategory().then((res) => {
                return res.data.data;
            }),
    });

    const { data: categoryNfts } = useQuery({
        queryKey: ['categoryNfts', categoryActive],
        queryFn: () =>
            getCategoryNFTs(categoryActive, { start: 0, length: 1000 }).then(
                (res) => {
                    return res.data.data;
                }
            ),
        enabled: categoryActive !== 'all-nfts',
    });

    useEffect(() => {
        categories?.length &&
            categories.forEach((element) => {
                if (element.sName.toLowerCase() === category) {
                    setCategoryActive(element._id);
                }
            });
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }, [category, categories]);

    return (
        <section>
            <div className='container  section-space-small'>
                <div className='text-center'>
                    <h2 className='main-heading-h2 -mt-7 md:-mt-2'>
                        Discover Exclusive Digital Assets
                    </h2>
                </div>
                <div className='pt-24 pb-12 2xl:pt-16 2xl:pb-6 lg:pt-12 lg:pb-0 sm:pt-10'>
                    <ul className='flex justify-center flex-wrap gap-8 md:gap-5 md:gap-y-2'>
                        <li onClick={() => setCategoryActive('all-nfts')}>
                            <span
                                className={`text-base inline-block font-bold text-dark-60 cursor-pointer duration-300 relative after:h-[3px] after:absolute after:-bottom-1 after:left-0 after:duration-300 after:bg-gradient after:opacity-0 after:w-full hover:text-black hover:after:opacity-100 md:text-sm ${categoryActive === 'all-nfts'
                                    ? '!text-black after:opacity-100'
                                    : ''
                                    }`}
                            >
                                All NFTs
                            </span>
                        </li>
                        {categories?.length
                            ? categories.map((item, i) => {
                                return (
                                    <li
                                        key={item._id}
                                        onClick={() =>
                                            setCategoryActive(item._id)
                                        }
                                    >
                                        <span
                                            className={`text-base inline-block font-bold text-dark-60 cursor-pointer duration-300 relative after:h-[3px] after:absolute after:-bottom-1 after:left-0 after:duration-300 after:bg-gradient after:opacity-0 after:w-full hover:text-black hover:after:opacity-100 md:text-sm ${categoryActive === item._id
                                                ? '!text-black after:opacity-100'
                                                : ''
                                                }`}
                                        >
                                            {item.sName}
                                        </span>
                                    </li>
                                );
                            })
                            : null}
                    </ul>
                </div>

                {categoryActive !== 'all-nfts' ? (
                    <div className='flex md:flex-wrap mt-14 sm:mt-10'>
                        <div
                            className={`flex-1 relative grid grid-cols-4 gap-x-5 gap-y-5 xl:grid-cols-3 md:grid-cols-2 sm:flex sm:flex-wrap ${categoryNfts?.length ? '' : '!grid-cols-1'
                                }`}
                        >
                            {categoryNfts?.length
                                ? categoryNfts.map((item, i) => {
                                    return (
                                        <div key={i} className='sm:w-full'>
                                            <CardMedium
                                                data={item}
                                                chainId={item?.nChainId}
                                            />
                                        </div>
                                    );
                                })
                                : !loader && (
                                    <div className='w-full h-[567px] flex items-center md:h-[450px]'>
                                        <NoDataFound />
                                    </div>
                                )}
                        </div>
                        {loader && !categoryNfts?.length && (
                            <div className='mt-20 w-full'>
                                <Loader />
                            </div>
                        )}
                    </div>
                ) : (
                    <div
                        className={`grid grid-cols-4 gap-x-5 gap-y-10 mt-14 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 sm:mt-10  ${brandNfts?.length ? '' : '!grid-cols-1'
                            }`}
                    >
                        {brandNfts?.length
                            ? brandNfts.map((item, i) => {
                                return item.aNfts.length ? (
                                    <div key={i} className=''>
                                        <CardLarge
                                            data={item}
                                            redirect={`/user/${item.sBrandName}`}
                                        />
                                    </div>
                                ) : null;
                            })
                            : !loader && (
                                <div className='h-[567px] flex items-center'>
                                    <NoDataFound />
                                </div>
                            )}
                    </div>
                )}
            </div>
            <CreateSellNfts />
        </section>
    );
}

export default AllBrands;
