import React from 'react';
import { useSelector } from 'react-redux';

import ProfileIcon from '../../assets/images/profile-img-icon.svg';

function ProfileBanner(props) {
    const {
        bannerImage,
        profileImage,
        isOwnProfile = false,
        fileOnChangeProfile,
        fileOnChangeCover,
    } = props;
    const user = useSelector((state) => state.user);

    return (
        <div className='h-80 bg-dark-10 relative md:h-72 sm:h-56'>
            {((isOwnProfile && user?.coverPicUrl) || bannerImage) && (
                <img
                    src={
                        ((isOwnProfile && user?.coverPicUrl) || bannerImage) +
                        `?${Date.now()}`
                    }
                    alt='InnerBanner'
                    className='absolute w-full h-full object-cover object-center left-0 top-0'
                />
            )}
            <div className='container h-full'>
                <div className='relative h-full'>
                    {isOwnProfile && !user?.coverPicUrl && (
                        <div className='absolute right-0 top-7'>
                            <button
                                className='btn-secondary'
                                // onClick={handleSubmit}
                            >
                                ADD COVER
                                <input
                                    type='file'
                                    onChange={fileOnChangeCover}
                                    accept='image/png, image/jpg, image/jpeg'
                                    className='absolute opacity-0 top-0 left-0 w-full h-full cursor-pointer'
                                />
                            </button>
                        </div>
                    )}
                    <div className='absolute left-0 bottom-0 rounded-full bg-dark-10/40 backdrop-blur p-3 translate-y-1/3'>
                        <div className='relative w-56 pt-[100%] rounded-full leading-none lg:w-48 sm:w-32 bg-gradient'>
                            {(isOwnProfile && user?.profilePicUrl) ||
                            profileImage ? (
                                <img
                                    src={
                                        ((isOwnProfile &&
                                            user?.profilePicUrl) ||
                                            profileImage) + `?${Date.now()}`
                                    }
                                    alt='profileImage'
                                    className='absolute rounded-full w-full h-full left-0 top-0 object-contain object-center'
                                />
                            ) : (
                                isOwnProfile && (
                                    <div className='absolute flex items-center justify-center rounded-full top-4 left-4 right-4 bottom-4 bg-black/40 backdrop-blur-lg cursor-pointer'>
                                        <img
                                            src={ProfileIcon}
                                            alt='ProfileIcon'
                                        />
                                        <input
                                            type='file'
                                            onChange={fileOnChangeProfile}
                                            accept='image/png, image/jpg, image/jpeg'
                                            className='absolute opacity-0 top-0 left-0 w-full h-full cursor-pointer'
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileBanner;
