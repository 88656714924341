import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

// IMAGES
import { useMutation } from "@tanstack/react-query";
import { addSubscriber } from "../../api/adapters/user";
import DiscordIcon from "../../assets/images/discord-icon.svg";
import EmailIcon from "../../assets/images/email-icon.svg";
import InstagramIcon from "../../assets/images/instagram-icon.svg";
import Logo from "../../assets/images/logo.svg";
import TelegramIcon from "../../assets/images/telegram-icon.svg";
import TwitterIcon from "../../assets/images/twitter-icon.svg";
import { notify } from "../../utils/common.helper";
import { isEmail } from "../../utils/helper";

function FooterMain() {
  const [cookies, setCookies] = useState(localStorage.getItem("cookies"));
  const acceptCookies = () => {
    localStorage.setItem("cookies", true);
    setCookies(true);
    notify("success", "Thank you for enabling cookies!");
  };
  const [email, setEmail] = useState("");

  const { mutate: mutateSubscribe } = useMutation({
    mutationFn: (email) => addSubscriber({ sEmail: email }),
    onSuccess: (data) => {
      notify("success", data.data.message);
    },
    onError: (error) => {
      notify("error", error.data.message || "Something went wrong!");
    },
  });

  const discoverList = [
    {
      title: "All NFTs",
      url: "/all-nfts",
    },
    {
      title: "Art",
      url: "/all-brands?category=art",
    },
    {
      title: "Photography",
      url: "/all-brands?category=photography",
    },
    {
      title: "Games",
      url: "/all-brands?category=games",
    },
    {
      title: "Music",
      url: "/all-brands?category=music",
    },
    {
      title: "Utility",
      url: "/all-brands?category=utility",
    },
    {
      title: "Collectibles",
      url: "/collections",
    },
    {
      title: "Domain",
      url: "/all-brands?category=domain",
    },
  ];

  const infoList = [
    {
      title: "Profile",
      url: "/my-profile",
    },
    {
      title: "My Collection",
      url: "/my-nft?collection=collection",
    },
  ];

  return (
    <footer className="border-t border-dark-20">
      <div className="container flex pt-16 pb-10 justify-between lg:flex-wrap lg:pb-16 lg:gap-5 xs:flex-col sm:py-10">
        <div className="max-w-xs xl:max-w-[280px] xs:max-w-none">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
          <p className="text-sm text-dark-60 pt-4 pb-3">
            Please contact us if you have any specific idea or request.
          </p>
          <p className="text-sm text-black py-3">
            Email:
            <Link
              to="mailto: admin@epiko.market"
              className="text-sm font-bold ml-1"
            >
              admin@epiko.market
            </Link>
          </p>
          <div>
            <ul className="xs:grid xs:grid-cols-2 xs:flex-wrap xs:gap-x-5">
              {/* <li className='py-1'>
                                <NavLink
                                    to='/about-us'
                                    className='text-sm text-dark-60 hover:text-dark-90 hover:underline'
                                >
                                    About Us
                                </NavLink>
                            </li> */}
              <li className="py-1">
                <NavLink
                  to="https://docs.epiko.io/miscellaneous/privacy-policy"
                  target="_blank"
                  className="text-sm text-dark-60 hover:text-dark-90 hover:underline"
                >
                  Privacy Policy
                </NavLink>
              </li>
              <li className="py-1">
                <NavLink
                  to="https://docs.epiko.io/miscellaneous/terms-of-use"
                  className="text-sm text-dark-60 hover:text-dark-90 hover:underline"
                  target="_blank"
                >
                  Terms of Use
                </NavLink>
              </li>
              <li className="py-1">
                <NavLink
                  to="https://docs.epiko.io/miscellaneous/faqs/epiko-market"
                  target="_blank"
                  className="text-sm text-dark-60 hover:text-dark-90 hover:underline"
                >
                  FAQs
                </NavLink>
              </li>
              <li className="py-1">
                <NavLink
                  to="/contact-us"
                  className="text-sm text-dark-60 hover:text-dark-90 hover:underline"
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="">
          <h6 className="text-xl text-black font-monserrat font-bold uppercase mb-4">
            Discover
          </h6>
          <div>
            <ul className="xs:grid xs:grid-cols-2 xs:flex-wrap xs:gap-x-5">
              {discoverList.length &&
                discoverList.map((item, i) => {
                  return (
                    <li className="py-1" key={i}>
                      <NavLink
                        to={item.url}
                        className="text-sm text-dark-60 hover:text-dark-90 hover:underline"
                      >
                        {item.title}
                      </NavLink>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="">
          <h6 className="text-xl text-black font-monserrat font-bold uppercase mb-4">
            INFO
          </h6>
          <div>
            <ul className="xs:grid xs:grid-cols-2 xs:flex-wrap xs:gap-x-5">
              {infoList.length &&
                infoList.map((item, i) => {
                  return (
                    <li className="py-1" key={i}>
                      <NavLink
                        to={item.url}
                        className="text-sm text-dark-60 hover:text-dark-90 hover:underline"
                      >
                        {item.title}
                      </NavLink>
                    </li>
                  );
                })}

              <Link
                to="mailto:support@epikomarket.com"
                className="text-sm text-dark-60 hover:text-dark-90 hover:underline"
              >
                Support
              </Link>
            </ul>
          </div>
        </div>
        <div className="">
          <h6 className="text-xl text-black font-monserrat font-bold uppercase mb-4">
            SOCIAL
          </h6>
          <div>
            <ul className="xs:grid xs:grid-cols-2 xs:flex-wrap xs:gap-x-5">
              <li className="py-1">
                <NavLink
                  to="https://x.com/The_Epiko"
                  target="_blank"
                  className="group text-sm text-dark-60 duration-300 hover:text-dark-90 hover:underline flex items-center"
                >
                  <img
                    src={TwitterIcon}
                    alt="TwitterIcon"
                    className="mr-1 opacity-50  duration-300 group-hover:opacity-100"
                  />
                  Twitter
                </NavLink>
              </li>
              <li className="py-1">
                <NavLink
                  to="https://discord.com/invite/J2ZCNMJXMN"
                  target="_blank"
                  className="group text-sm text-dark-60 duration-300 hover:text-dark-90 hover:underline flex items-center"
                >
                  <img
                    src={DiscordIcon}
                    alt="DiscordIcon"
                    className="mr-1 opacity-50 duration-300 group-hover:opacity-100"
                  />
                  Discord
                </NavLink>
              </li>
              <li className="py-1">
                <NavLink
                  to="https://t.me/theepiko"
                  target="_blank"
                  className="group text-sm text-dark-60 duration-300 hover:text-dark-90 hover:underline flex items-center"
                >
                  <img
                    src={TelegramIcon}
                    alt="TelegramIcon"
                    className="mr-1 opacity-50 duration-300 group-hover:opacity-100"
                  />
                  Telegram
                </NavLink>
              </li>
              <li className="py-1">
                <NavLink
                  to="https://www.instagram.com/the_epiko/"
                  target="_blank"
                  className="group text-sm text-dark-60 duration-300 hover:text-dark-90 hover:underline flex items-center"
                >
                  <img
                    src={InstagramIcon}
                    alt="InstagramIcon"
                    className="mr-1 opacity-50 duration-300 group-hover:opacity-100"
                  />
                  Instagram
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="max-w-md w-full xl:max-w-sm lg:max-w-none">
          <h6 className="text-xl text-black font-monserrat font-bold uppercase mb-4">
            Subscribe to our newsletter
          </h6>
          <p className="text-sm text-dark-60 mb-5">
            For the latest news, drops & collectibles.
          </p>
          <div className="relative grow">
            <span className="absolute inset-y-0 left-5 flex items-center xs:left-3">
              <img src={EmailIcon} alt="EmailIcon" />
            </span>
            <input
              className="placeholder:text-dark-80 text-dark-60 block bg-dark-10 w-full border !border-dark-30 rounded-xl py-5 pr-32 pl-14 focus:outline-none focus:ring-0 focus:!border-dark-70 sm:text-sm lg:py-4 xs:pr-24 xs:pl-10"
              placeholder="Enter your email"
              type="text"
              name="search"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="btn-primary absolute right-1.5 top-1.5 z-10 xs:px-3"
              onClick={(e) => {
                e.preventDefault();
                let isValidEmail = isEmail(email.trim());
                if (!isValidEmail) {
                  notify("error", "Please enter a valid email address!");
                  return;
                }
                mutateSubscribe(email.trim());
                setEmail("");
              }}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
      <div className="border-t border-dark-20">
        <div className="container flex justify-between items-center py-7 md:flex-col md:py-5">
          <p className="text-sm text-dark-80 md:text-xs md:pb-3">
            Copyright © 2025 Epiko Market. All rights reserved
          </p>
          {!cookies && (
            <div className="flex items-center">
              <p className="text-sm text-dark-80 md:text-xs">
                We use cookies for better service.
              </p>
              <Link
                to="#"
                className="btn-border btn-sm ml-2"
                onClick={acceptCookies}
              >
                Accept
              </Link>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
}

export default FooterMain;
