import { useQuery } from "@tanstack/react-query";
import React, { useState, memo } from "react";
import { useDispatch } from "react-redux";
import { getOmiBurnStats, getStats } from "../../api/adapters/platform-stats";
import CHAINS_WEB3 from "../../configs/web3-chains";
import { toggleLoader } from "../../redux/actions/loader";
import {
  chainSymbolById,
  customCurrencyFormat,
  formatNumber,
  parseDate,
  trailingAddress,
} from "../../utils/helper";

const PlatformStat = memo(({ data }) => {

  return (
    <div className="flex gap-6 py-14 sm:flex-wrap sm:gap-4 lg:py-12 sm:py-8">
      <div className="rounded-xl p-6 bg-[#E6FBFF] flex-1 lg:p-5 sm:p-4">
        <p className="text-lg font-bold text-[#78A1B8] lg:text-base sm:text-sm">
          Number of Registered Users
        </p>
        <h5 className="text-3xl text-cyan-950 font-extrabold font-monserrat 2xl:text-2.5xl sm:text-2xl">
          {customCurrencyFormat(data?.totalUsers) || 0}
        </h5>
      </div>
      <div className="rounded-xl p-6 bg-secondary/20 flex-1 lg:p-5">
        <p className="text-lg font-bold text-[#966D9D] lg:text-base sm:text-sm">
          Number of NFTs Sold
        </p>
        <h5 className="text-3xl text-[#2D1B30] font-extrabold font-monserrat 2xl:text-2.5xl sm:text-2xl">
          {customCurrencyFormat(
            data?.totalNFTsSold || 0
          )}
        </h5>
      </div>
      <div className="rounded-xl p-6 bg-tertiary/20 flex-1 lg:p-5">
        <p className="text-lg font-bold text-[#6D643D] lg:text-base sm:text-sm">
          Total Sales Volume
        </p>
        <h5 className="text-3xl text-[#362F10] font-extrabold font-monserrat 2xl:text-2.5xl sm:text-2xl">
          {/* $5,42,563 */}
          {customCurrencyFormat(
            data?.totalSalesVolume || 0,
            true
          )}
        </h5>
      </div>
    </div>
  )
})


function Stats() {
  const [tabActive, setTabActive] = useState("platform_activities");
  const [filter, setFilter] = useState([]);
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalNFTsSold: 0,
    totalSalesVolume: 0,
  });
  const dispatch = useDispatch();

  const filterList = [
    {
      id: "List",
      name: "Listings",
    },
    {
      id: 2,
      name: "Sales",
    },
    {
      id: 3,
      name: "Bids",
    },
    {
      id: 4,
      name: "Transfer",
    },
  ];
  const allowedEvents = [
    "Burn",
    "Bid",
    "Bid Accepted",
    "Sold",
    "Transfer",
    "List",
    "On Auction",
    "Mint",
    "Timed Auction",
    "Claimed",
  ];

  const { data: platformStats } = useQuery({
    queryKey: ["platformStats", filter],
    queryFn: () =>
      getStats({ oFilters: filter }).then((res) => {
        setTimeout(() => {
          dispatch(toggleLoader(false));
        }, 300);

        setStats({
          totalUsers: res.data.data?.nTotalUsers || 0,
          totalNFTsSold: res.data.data?.nTotalSoldNFts?.[0]?.count || 0,
          totalSalesVolume: res.data.data?.nTotalSoldNFts?.[0]?.volume || 0,
        });
        return res.data.data;
      }),
  });

  const { data: burnedTokens } = useQuery({
    queryKey: ["burnedTokens"],
    queryFn: () =>
      getOmiBurnStats().then((res) => {
        return res.data.data;
      }),
  });

  return (
    <section className="">
      <div className="container section-space-small">
        <div className="text-center">
          <h1 className="main-heading-h2 inline-block">Platform Stats</h1>
        </div>
        <PlatformStat data={stats} />
        <div>
          <ul className="flex flex-wrap gap-8 mb-14 md:gap-5 md:gap-y-2 lg:mb-12 sm:mb-8">
            <li onClick={() => setTabActive("platform_activities")}>
              <span
                className={`text-lg inline-block font-bold text-dark-60 cursor-pointer duration-300 relative after:h-[3px] after:absolute after:-bottom-1 after:left-0 after:duration-300 after:bg-gradient after:opacity-0 after:w-full hover:text-black hover:after:opacity-100 md:text-sm ${tabActive === "platform_activities"
                  ? "!text-black after:opacity-100"
                  : ""
                  }`}
              >
                Platform Activities
              </span>
            </li>
            <li onClick={() => setTabActive("burning_stats_token")}>
              <span
                className={`text-lg inline-block font-bold text-dark-60 cursor-pointer duration-300 relative after:h-[3px] after:absolute after:-bottom-1 after:left-0 after:duration-300 after:bg-gradient after:opacity-0 after:w-full hover:text-black hover:after:opacity-100 md:text-sm ${tabActive === "burning_stats_token"
                  ? "!text-black after:opacity-100"
                  : ""
                  }`}
              >
                Burning Stats of OMI Token
              </span>
            </li>
          </ul>
          {tabActive === "platform_activities" && (
            <div className="flex xl:flex-col">
              <div className="w-1/6 xl:w-full">
                <h6 className="text-lg font-bold text-black mb-5">
                  Event Type
                </h6>
                <div className="flex flex-col flex-wrap xl:flex-row xl:gap-x-5 xl:mb-5">
                  {filterList.map((option) => (
                    <div key={option.id} className="flex items-center mb-2">
                      <input
                        id={option.name}
                        name={option.name}
                        type="checkbox"
                        className="h-[18px] w-[18px] rounded border-2 border-dark-80 text-primary focus:ring-transparent"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFilter([...filter, option.name]);
                          } else {
                            setFilter(
                              filter.filter((item) => item !== option.name)
                            );
                          }
                        }}
                      />
                      <label
                        htmlFor={option.name}
                        className="ml-3 min-w-0 flex-1 text-base font-medium text-dark-80"
                      >
                        {option.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex-1">
                <div className="flex flex-col gap-y-2.5">
                  <div className="flex gap-4 px-4 pb-3 text-md text-dark-60 font-medium xl:hidden">
                    <div className="w-[15%]">
                      <p>Event</p>
                    </div>
                    <div className="w-[22%]">
                      <p>NFT</p>
                    </div>
                    <div className="w-[15%]">
                      <p>Price</p>
                    </div>

                    <div className="w-[17%]">
                      <p>From</p>
                    </div>
                    <div className="w-[17%]">
                      <p>To</p>
                    </div>
                    <div className="w-[14%]">
                      <p>Date</p>
                    </div>
                  </div>
                  {platformStats?.aAllActivities?.length
                    ? platformStats?.aAllActivities?.map((item, i) => {
                      return (
                        allowedEvents.includes(item.eBidStatus) && (
                          <div
                            key={i}
                            className="flex items-center gap-4 px-4 py-8  border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0"
                          >
                            <div className="w-[15%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30">
                              <div className="hidden w-1/3 text-sm text-dark-60 font-medium lg:block">
                                <p>Event</p>
                              </div>
                              <div className="flex items-center gap-3">
                                <p>
                                  {item.eBidStatus === "Claimed"
                                    ? "Sold"
                                    : item.eBidStatus}
                                </p>
                              </div>
                            </div>
                            <div className="w-[22%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30">
                              <div className="hidden w-1/3 text-sm text-dark-60 font-medium lg:block">
                                <p>NFT</p>
                              </div>
                              <div className="flex items-center gap-4">
                                <p>{item.aNFT.sName}</p>
                              </div>
                            </div>
                            <div className="w-[15%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30">
                              <div className="hidden w-1/3 text-sm text-dark-60 font-medium lg:block">
                                <p>Price</p>
                              </div>
                              <div className="flex items-center gap-2.5">
                                <p>
                                  {formatNumber(item.nBidPrice)}{" "}
                                  {item?.sTokenSymbol ||
                                    chainSymbolById(item.aNFT.nChainId)}
                                </p>
                              </div>
                            </div>
                            <div className="w-[17%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30">
                              <div className="hidden w-1/3 text-sm text-dark-60 font-medium lg:block">
                                <p>From</p>
                              </div>
                              <div className="flex items-center gap-4">
                                <p>
                                  {item.eBidStatus === "Mint"
                                    ? "N/A"
                                    : item.aFrom?.sUserName}
                                </p>
                              </div>
                            </div>
                            <div className="w-[17%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30">
                              <div className="hidden w-1/3 text-sm text-dark-60 font-medium lg:block">
                                <p>To</p>
                              </div>
                              <p>
                                {item.eBidStatus === "Mint"
                                  ? item.aFrom?.sUserName
                                  : item.eBidStatus === "Burn"
                                    ? "N/A"
                                    : item.aTo?.sUserName}
                              </p>
                            </div>
                            <div className="w-[14%] lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30">
                              <div className="hidden w-1/3 text-sm text-dark-60 font-medium lg:block">
                                <p>Date</p>
                              </div>
                              <div className="flex items-center gap-4">
                                <p>{parseDate(item.dCreatedAt)}</p>
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })
                    : null}
                </div>
              </div>
            </div>
          )}
          {tabActive === "burning_stats_token" && (
            <div className="">
              <div className="flex flex-col gap-y-2.5">
                <div className="flex gap-4 px-4 pb-3 text-md text-dark-60 font-medium xl:hidden">
                  <div className="w-1/4">
                    <p>User</p>
                  </div>
                  <div className="w-1/4">
                    <p>OMI Tokens</p>
                  </div>
                  <div className="w-1/4">
                    <p>Transaction ID</p>
                  </div>
                  <div className="w-1/4">
                    <p>Date</p>
                  </div>
                </div>
                {burnedTokens?.length
                  ? burnedTokens?.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="flex items-center gap-4 px-4 py-8  border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0"
                      >
                        <div className="w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30">
                          <div className="hidden w-5/12 text-sm text-dark-60 font-medium lg:block">
                            <p>User</p>
                          </div>
                          <div className="flex items-center gap-3">
                            <p>
                              {trailingAddress(item.aFrom.sWalletAddress, 15)}
                            </p>
                          </div>
                        </div>
                        <div className="w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30">
                          <div className="hidden w-5/12 text-sm text-dark-60 font-medium lg:block">
                            <p>OMI Tokens</p>
                          </div>
                          <div className="flex items-center gap-4">
                            <p>{formatNumber(item.nBidPrice)}</p>
                          </div>
                        </div>
                        <div className="w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30">
                          <div className="hidden w-5/12 text-sm text-dark-60 font-medium lg:block">
                            <p>Transaction ID</p>
                          </div>
                          <div className="flex items-center gap-2.5">
                            <a
                              href={`${CHAINS_WEB3[5].blockExplorerUrls}/tx/${item?.sTransactionHash}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <p>
                                {trailingAddress(item.sTransactionHash, 15)}
                              </p>
                            </a>
                          </div>
                        </div>
                        <div className="w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30">
                          <div className="hidden w-5/12 text-sm text-dark-60 font-medium lg:block">
                            <p>Date</p>
                          </div>
                          <div className="flex items-center gap-4">
                            <p>{parseDate(item.dCreatedAt)}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : null}

                {/* <div className='flex items-center gap-4 px-4 py-8  border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0'>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Users</p>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <p>0xb3A9Acb3A9Ac</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>OMI Tokens</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>10.50 omi</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Transaction ID</p>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <p>564665448</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Date</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>2 Hours Ago</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center gap-4 px-4 py-8  border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0'>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Users</p>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <p>0xb3A9Acb3A9Ac</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>OMI Tokens</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>10.50 omi</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Transaction ID</p>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <p>564665448</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Date</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>2 Hours Ago</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center gap-4 px-4 py-8  border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0'>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Users</p>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <p>0xb3A9Acb3A9Ac</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>OMI Tokens</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>10.50 omi</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Transaction ID</p>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <p>564665448</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Date</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>2 Hours Ago</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center gap-4 px-4 py-8  border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0'>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Users</p>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <p>0xb3A9Acb3A9Ac</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>OMI Tokens</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>10.50 omi</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Transaction ID</p>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <p>564665448</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Date</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>2 Hours Ago</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center gap-4 px-4 py-8  border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0'>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Users</p>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <p>0xb3A9Acb3A9Ac</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>OMI Tokens</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>10.50 omi</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Transaction ID</p>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <p>564665448</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Date</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>2 Hours Ago</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center gap-4 px-4 py-8  border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0'>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Users</p>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <p>0xb3A9Acb3A9Ac</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>OMI Tokens</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>10.50 omi</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Transaction ID</p>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <p>564665448</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Date</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>02/05/2023</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center gap-4 px-4 py-8  border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0'>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Users</p>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <p>0xb3A9Acb3A9Ac</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>OMI Tokens</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>10.50 omi</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Transaction ID</p>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <p>564665448</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Date</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>02/05/2023</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center gap-4 px-4 py-8  border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0'>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Users</p>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <p>0xb3A9Acb3A9Ac</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>OMI Tokens</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>10.50 omi</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Transaction ID</p>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <p>564665448</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Date</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>02/05/2023</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center gap-4 px-4 py-8  border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0'>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Users</p>
                                        </div>
                                        <div className='flex items-center gap-3'>
                                            <p>0xb3A9Acb3A9Ac</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>OMI Tokens</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>10.50 omi</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Transaction ID</p>
                                        </div>
                                        <div className='flex items-center gap-2.5'>
                                            <p>564665448</p>
                                        </div>
                                    </div>
                                    <div className='w-1/4 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                        <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                            <p>Date</p>
                                        </div>
                                        <div className='flex items-center gap-4'>
                                            <p>02/05/2023</p>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Stats;
